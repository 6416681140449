import ModelService from './ModelService';
const modelService = new ModelService();

const model = 'formulas';

export async function PutFormula(id, formData) {
  return modelService.PutModel(`${model}/${id}`, formData);
}

export async function PostFormula(formData) {
  return modelService.PostModel(`${model}`, formData);
}

export async function DeleteFormula(id) {
  return modelService.DeleteModel(`${model}/${id}`);
}
