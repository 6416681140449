import { toast } from 'react-toastify';

export default function RequireAuthEmployee(user, navigate) {
  if (!localStorage.getItem('accessToken') && !user) {
    toast('Veuillez vous connecter pour accéder au contenu');
    navigate('/login');
  } else if (user.role_id !== 2) {
    toast("Vous n'êtes pas autorisé à accéder à cette page.");
    navigate('/');
  } else {
    if (!user.profile_picture_url) {
      navigate('/salaries/profil/photo-de-profil');
    } else if (
      !user.salary_coefficient &&
      user.company &&
      user.company.formulas &&
      user.company.formulas.filter(
        formula => formula.conditions && formula.conditions.length > 0
      ).length > 0
    ) {
      navigate('/salaries/profil/coefficient');
    }
  }
}
