import React, { useEffect } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import Section from 'components/common/Section';
import { Link, Outlet } from 'react-router-dom';
import logo from 'assets/img/logo-ciarus-gourmet.png';
import RedirectIfAuthenticated from '../../../middlewares/RedirectIfAuthenticated';
import { useNavigate } from 'react-router';

const AuthSimpleLayoutCiarus = () => {
  const navigate = useNavigate();
  useEffect(() => {
    RedirectIfAuthenticated(navigate);
  }, []);
  return (
    <Section className="py-0">
      <Row className="flex-center min-vh-100 pb-6 pt-0">
        <Col sm={10} md={8} lg={6} xl={5} className="col-xxl-4">
          <Link to="/" className="text-decoration-none">
            <div className="d-flex flex-center fw-bolder fs-5 mb-4">
              <img className="me-2" src={logo} alt="Logo" width={250} />
            </div>
          </Link>
          <Card>
            <Card.Body className="p-4 p-sm-5">
              <Outlet />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Section>
  );
};

export default AuthSimpleLayoutCiarus;
