import React, { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Offcanvas,
  Row
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useParams } from 'react-router';
import FormAlert from '../../../commons/FormAlert';
import {
  DeleteUser,
  PostUser,
  PutUser,
  ResendUserWelcomeEmail,
  ShowUser
} from '../../../../../services/UserService';
import { useNavigate } from 'react-router-dom';
import { IndexCompany } from '../../../../../services/CompanyService';
import FileUploader from '../../../commons/fileUploader';

const ManageEmployees = () => {
  const [trigger] = useState(Date.now());
  const [companies, setCompanies] = useState([]);
  const [user, setUser] = useState({});
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [errorForm, setErrorForm] = useState(false);
  const [successForm, setSuccessForm] = useState(false);
  const [formData, setFormData] = useState({
    company_id: '',
    firstname: '',
    lastname: '',
    phone: '',
    registration_number: '',
    email: '',
    profile_picture_url: '',
    role_id: 2,
    salary_coefficient: '',
    active: 0
  });
  let navigate = useNavigate();

  useEffect(() => {
    const fetchUser = async () => {
      const responseUser = await ShowUser(id, 2);
      if (responseUser.success === true) {
        let dataUser = responseUser.data;
        setUser(dataUser);
        setFormData({
          company_id: dataUser.company_id,
          firstname: dataUser.firstname,
          lastname: dataUser.lastname,
          phone: dataUser.phone,
          registration_number: dataUser.registration_number,
          email: dataUser.email,
          profile_picture_url: dataUser.profile_picture_url,
          role_id: 2,
          salary_coefficient: dataUser.salary_coefficient,
          active: dataUser.active
        });
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    const fetchCompanies = async () => {
      const responseCompanies = await IndexCompany();
      if (responseCompanies.success === true) {
        setCompanies(responseCompanies.data);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    fetchCompanies();
    if (id) {
      fetchUser();
    }
  }, [trigger]);

  const deleteObject = async () => {
    const response = await DeleteUser(id);
    if (response.success === true) {
      toast('Salarié archivé');
      navigate('/administration/salaries');
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
  };

  const resendWelcomeEmail = async email => {
    const response = await ResendUserWelcomeEmail(id, {
      email: email
    });
    setSuccessForm(false);
    setErrorForm(response.validator);
    setTimeout(function () {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 250);
    if (response.success === true && response.data) {
      toast(`Email envoyé à l'adresse ${response.data.email}`);
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
  };

  const handleFieldChange = e => {
    if (e.target.name === 'active') {
      setFormData({
        ...formData,
        [e.target.name]: e.target.checked ? 1 : 0
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value
      });
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setIsLoading(true);
    let response = false;
    if (id) {
      response = await PutUser('admin.updateform.salariee', id, formData);
    } else {
      response = await PostUser(formData);
    }
    setSuccessForm(false);
    setErrorForm(response.validator);
    setTimeout(function () {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 250);
    setIsLoading(false);
    if (response.success === true) {
      const successMessage = `Salarié ${response.data.firstname} ${
        response.data.lastname
      } enregistré.${
        !id ? ' Une invitation automatique lui a été envoyée par mail.' : ''
      }`;
      toast(successMessage);
      setSuccessForm(successMessage);
      setIsLoading(true);
      setTimeout(function () {
        window.location.href = `/administration/salaries`;
      }, 4000);
    }
  };

  return (
    <>
      {((id && user.id !== null && user.id !== undefined) || !id) && (
        <>
          <Offcanvas
            show={showDelete}
            onHide={() => {
              setShowDelete(false);
            }}
            placement={'end'}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Voulez-vous continuer ?</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Button
                size="sm"
                variant="falcon-danger"
                className="me-2"
                onClick={() => {
                  deleteObject();
                }}
              >
                Oui, archiver le salarié
              </Button>
              <Button
                size="sm"
                variant="falcon-default"
                className="me-2"
                onClick={() => {
                  setShowDelete(false);
                }}
              >
                Annuler
              </Button>
            </Offcanvas.Body>
          </Offcanvas>
          <Form onSubmit={handleSubmit}>
            <Row className="g-3">
              <Col xs={12}>
                <Card>
                  <Card.Body>
                    <Row className="flex-between-center">
                      <Col md>
                        <h3 className="mb-2 mb-md-0 text-primary admin-title mb-0">
                          {' '}
                          {user.id !== null && user.id !== undefined
                            ? `Modifier les informations du salarié`
                            : 'Ajouter un salarié'}
                        </h3>
                      </Col>
                      <Col xs="auto">
                        <Button
                          size="sm"
                          variant="falcon-default"
                          className="me-2"
                          onClick={() => {
                            window.location.href = '/administration/salaries';
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="20"
                            height="20"
                            style={{ marginBottom: '2px' }}
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M10.828 12l4.95 4.95-1.414 1.414L8 12l6.364-6.364 1.414 1.414z" />
                          </svg>
                          {!id ? 'Annuler' : 'Retour'}
                        </Button>
                        <Button
                          size="sm"
                          variant="primary"
                          className="me-2 admin-primary"
                          type="submit"
                          disabled={isLoading}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="18"
                            height="18"
                            style={{ marginBottom: '2px', marginRight: '4px' }}
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path
                              d="M7 19v-6h10v6h2V7.828L16.172 5H5v14h2zM4 3h13l4 4v13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm5 12v4h6v-4H9z"
                              fill="rgba(255,255,255,1)"
                            />
                          </svg>
                          Enregistrer
                        </Button>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
                {errorForm && (
                  <div className="mt-3">
                    <FormAlert variant="danger" data={errorForm} />
                  </div>
                )}
                {successForm && (
                  <div className="mt-3">
                    <FormAlert
                      className="mt-3"
                      variant="success"
                      data={successForm}
                    />
                  </div>
                )}
              </Col>
              <Col lg={12}>
                <Card className="mb-3 pb-3">
                  <Card.Body className="bg-light">
                    <Row className="gx-2 gy-3">
                      <Col xs={6}>
                        <Form.Group>
                          <Form.Label>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="17"
                              height="17"
                              style={{
                                marginRight: '4px',
                                marginBottom: '2px'
                              }}
                            >
                              <path
                                d="M4.828 21l-.02.02-.021-.02H2.992A.993.993 0 0 1 2 20.007V3.993A1 1 0 0 1 2.992 3h18.016c.548 0 .992.445.992.993v16.014a1 1 0 0 1-.992.993H4.828zM20 15V5H4v14L14 9l6 6zm0 2.828l-6-6L6.828 19H20v-1.172zM8 11a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
                                fill="rgba(100,99,99,1)"
                              />
                            </svg>
                            Photo de profil :
                          </Form.Label>
                          {id && user.id ? (
                            <FileUploader
                              pathToUpload="users/profilepics"
                              formData={formData}
                              setFormData={setFormData}
                              format="img"
                              databaseField="profile_picture_url"
                              cropperEnabled={true}
                              currentImage={user.profile_picture_url}
                            />
                          ) : (
                            <FileUploader
                              pathToUpload="users/profilepics"
                              formData={formData}
                              setFormData={setFormData}
                              format="img"
                              databaseField="profile_picture_url"
                              cropperEnabled={true}
                            />
                          )}
                        </Form.Group>
                      </Col>
                      <Col md="12" className="pb-2 pt-3">
                        <div className="border-dashed-bottom"></div>
                      </Col>
                      <Col md="6">
                        <Form.Group controlId="firstname">
                          <Form.Label>Prénom :</Form.Label>
                          <Form.Control
                            type="text"
                            name="firstname"
                            placeholder="Prénom du membre"
                            value={formData.firstname || ''}
                            onChange={handleFieldChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col md="6">
                        <Form.Group controlId="lastname">
                          <Form.Label>Nom :</Form.Label>
                          <Form.Control
                            type="text"
                            name="lastname"
                            placeholder="Nom du membre"
                            value={formData.lastname || ''}
                            onChange={handleFieldChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col md="6">
                        <Form.Group controlId="email">
                          <Form.Label>Email :</Form.Label>
                          <Form.Control
                            type="email"
                            name="email"
                            placeholder="Adresse email"
                            value={formData.email || ''}
                            onChange={handleFieldChange}
                          />
                        </Form.Group>
                        {id && (
                          <small>
                            <a
                              className="cursor-pointer"
                              onClick={() => {
                                resendWelcomeEmail(formData.email);
                              }}
                            >
                              Renvoyer l'email d'inscription
                            </a>
                          </small>
                        )}
                      </Col>
                      <Col md="6">
                        <Form.Group controlId="phone">
                          <Form.Label>Téléphone :</Form.Label>
                          <Form.Control
                            type="text"
                            name="phone"
                            placeholder="Numéro de téléphone"
                            value={formData.phone || ''}
                            onChange={handleFieldChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col md="12" className="pb-2 pt-3">
                        <div className="border-dashed-bottom"></div>
                      </Col>
                      <Col md="12">
                        <Form.Group controlId="company_id">
                          <Form.Label>Société :</Form.Label>
                          <Form.Select
                            aria-label="Société"
                            name="company_id"
                            value={formData.company_id}
                            onChange={handleFieldChange}
                          >
                            <option value="">Choisir...</option>
                            {companies.map((c, index) => (
                              <option value={c.id} key={index}>
                                {c.name}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col md="6">
                        <Form.Group controlId="registration_number">
                          <Form.Label>Matricule :</Form.Label>
                          <Form.Control
                            type="text"
                            name="registration_number"
                            placeholder="AE7417"
                            value={formData.registration_number || ''}
                            onChange={handleFieldChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col md="6">
                        <Form.Group controlId="salary_coefficient">
                          <Form.Label>Indice :</Form.Label>
                          <Form.Control
                            type="number"
                            name="salary_coefficient"
                            placeholder="100"
                            value={formData.salary_coefficient || ''}
                            onChange={handleFieldChange}
                          />
                        </Form.Group>
                        {!formData.salary_coefficient &&
                          companies.length > 0 &&
                          formData.company_id &&
                          companies.filter(
                            c => c.id === parseInt(formData.company_id)
                          )[0] &&
                          companies.filter(
                            c => c.id === parseInt(formData.company_id)
                          )[0].formulas &&
                          companies
                            .filter(
                              c => c.id === parseInt(formData.company_id)
                            )[0]
                            .formulas.filter(
                              f => f.conditions && f.conditions.length > 0
                            ).length > 0 && (
                            <Alert variant="warning" className="mt-3">
                              <p className="mb-0">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  width="20"
                                  height="20"
                                  style={{
                                    marginBottom: '2px',
                                    marginRight: '2px'
                                  }}
                                >
                                  <path fill="none" d="M0 0h24v24H0z" />
                                  <path
                                    d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-1-7v2h2v-2h-2zm0-8v6h2V7h-2z"
                                    fill="rgba(147,35,56,1)"
                                  />
                                </svg>
                                <b>
                                  Il est fortement recommandé de configurer
                                  l'indice
                                </b>{' '}
                                car des formules de la société sélectionnée
                                contiennent des conditions basées sur cette
                                donnée.
                                <br />
                                <br />
                                <b>
                                  Le cas échéant, le salarié ne pourra pas
                                  générer de tickets pour les formules
                                  concernées.
                                </b>
                              </p>
                            </Alert>
                          )}
                      </Col>
                      <Col md="12" className="pb-2 pt-3">
                        <div className="border-dashed-bottom"></div>
                      </Col>
                      <Col xs={12} className="mt-3">
                        <Alert variant="info">
                          <label>
                            <input
                              type="checkbox"
                              id="active"
                              name="active"
                              value="1"
                              className="me-1"
                              checked={
                                formData.active === 1 || formData.active === '1'
                              }
                              onChange={handleFieldChange}
                              style={{ position: 'relative', bottom: '-1px' }}
                            />
                            Compte actif ?
                          </label>
                          <span className="d-block smaller-p mb-0">
                            <b>
                              Cocher cette case permet au salarié de pouvoir
                              générer des tickets repas.
                            </b>
                          </span>
                        </Alert>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12} style={{ marginBottom: '150px' }}>
                <Card>
                  <Card.Body>
                    <Row className="flex-between-center">
                      <Col xs="12">
                        <Button
                          size="sm"
                          variant="falcon-default"
                          className="me-2"
                          onClick={() => {
                            window.location.href = '/administration/salaries';
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="20"
                            height="20"
                            style={{ marginBottom: '2px' }}
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M10.828 12l4.95 4.95-1.414 1.414L8 12l6.364-6.364 1.414 1.414z" />
                          </svg>
                          {!id ? 'Annuler' : 'Retour'}
                        </Button>
                        <Button
                          size="sm"
                          variant="primary"
                          className="me-2 admin-primary"
                          type="submit"
                          disabled={isLoading}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="18"
                            height="18"
                            style={{ marginBottom: '2px', marginRight: '4px' }}
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path
                              d="M7 19v-6h10v6h2V7.828L16.172 5H5v14h2zM4 3h13l4 4v13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm5 12v4h6v-4H9z"
                              fill="rgba(255,255,255,1)"
                            />
                          </svg>
                          Enregistrer
                        </Button>
                        {id && (
                          <Button
                            size="sm"
                            variant="danger"
                            className="me-0 float-end"
                            type="button"
                            onClick={() => {
                              setShowDelete(true);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="14"
                              height="14"
                              style={{
                                marginBottom: '2px',
                                marginRight: '3px'
                              }}
                            >
                              <path
                                d="M7 4V2h10v2h5v2h-2v15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V6H2V4h5zM6 6v14h12V6H6zm3 3h2v8H9V9zm4 0h2v8h-2V9z"
                                fill="rgba(255,255,255,1)"
                              />
                            </svg>
                            Archiver
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Form>
        </>
      )}
    </>
  );
};

export default ManageEmployees;
