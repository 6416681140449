import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ExcelSheet from 'react-export-excel/dist/ExcelPlugin/elements/ExcelSheet';
import ExcelColumn from 'react-export-excel/dist/ExcelPlugin/elements/ExcelColumn';
import ExcelFile from 'react-export-excel/dist/ExcelPlugin/components/ExcelFile';
import IconButton from '../components/common/IconButton';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';

const ExportTickets = ({ tickets }) => {
  const [cleanData, setCleanData] = useState([]);
  let tmpCleanData = [];
  useEffect(() => {
    tickets.map(user => {
      let cleanedCode = {
        user: user.user,
        company: user.company,
        salary_coefficient: user.salary_coefficient,
        registration_number: user.registration_number,
        formula: user.formula,
        value: user.value_toString,
        validated_at: user.validated_at
      };
      tmpCleanData.push(cleanedCode);
    });
    setCleanData(tmpCleanData);
  }, [tickets]);
  return (
    <>
      {cleanData.length > 0 && (
        <>
          <ExcelFile
            element={
              <IconButton
                className="mb-1 w-auto float-end"
                variant="falcon-default"
                size="sm"
                icon={faFileExcel}
                transform="shrink-3"
              >
                Exporter la liste
              </IconButton>
            }
          >
            <ExcelSheet data={cleanData} name="Membres">
              <ExcelColumn label="Salarié" value="user" />
              <ExcelColumn label="Société" value="company" />
              <ExcelColumn label="Indice" value="salary_coefficient" />
              <ExcelColumn label="Matricule" value="registration_number" />
              <ExcelColumn label="Formule" value="formula" />
              <ExcelColumn label="Société" value="company" />
              <ExcelColumn label="Montant participation" value="value" />
              <ExcelColumn label="Passage en caisse" value="validated_at" />
            </ExcelSheet>
          </ExcelFile>
        </>
      )}
    </>
  );
};

ExportTickets.propTypes = {
  tickets: PropTypes.array
};

export default ExportTickets;
