import React, { useState, useEffect } from 'react';
import { Offcanvas, Form, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { PostCompany, PutCompany } from '../../../../services/CompanyService';
import { toast } from 'react-toastify';

const ManageCompany = ({
  showManageCompany,
  setShowManageCompany,
  setparentTrigger,
  object
}) => {
  const [formData, setFormData] = useState({
    name: ''
  });
  useEffect(() => {
    if (object) {
      setFormData({
        name: object.name
      });
    }
  }, []);

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    let response = false;
    if (object) {
      response = await PutCompany(object.id, formData);
    } else response = await PostCompany(formData);
    if (response.success) {
      setparentTrigger(Date.now);
      setShowManageCompany(false);
      toast(object ? 'Société modifiée' : 'Société créée');
    } else {
      toast('Une erreur est survenue');
    }
  };

  return (
    <>
      <Offcanvas
        placement={'end'}
        show={showManageCompany}
        onHide={() => {
          setShowManageCompany(false);
        }}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            {object ? 'Modifier la société' : 'Créer une société'}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form>
            <Form.Group controlId="name">
              <Form.Label>Nom :</Form.Label>
              <Form.Control
                type="text"
                name="name"
                placeholder="Nom de la société"
                value={formData.name || ''}
                onChange={handleFieldChange}
              />
            </Form.Group>

            <Button onClick={handleSubmit} size="sm" className="mt-3">
              Enregistrer
            </Button>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default ManageCompany;

ManageCompany.propTypes = {
  showManageCompany: PropTypes.bool,
  object: PropTypes.object,
  setShowManageCompany: PropTypes.func,
  setparentTrigger: PropTypes.func
};
