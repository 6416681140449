import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import logoCiarusRestaurant from 'assets/img/logo-ciarus-gourmet.png';
import classNames from 'classnames';

export default function HeaderCashier({ title, isSmall }) {
  return (
    <Row>
      <Col xs={12}>
        <Row
          className="justify-content-center"
          style={{ backgroundColor: '#1c1c1d' }}
        >
          <Col xs={11}>
            <Row className="align-items-center pt-3 pb-1">
              <Col
                xs={6}
                className="text-start"
                style={{ backgroundColor: '#1c1c1d' }}
              >
                <img
                  src={logoCiarusRestaurant}
                  alt="Logo Ciarus-restaurant"
                  className="cursor-pointer"
                  onClick={() => {
                    window.location.href = '/caissiers/accueil';
                  }}
                  style={{ maxWidth: '75px' }}
                />
              </Col>
              <Col xs={6} />
            </Row>
          </Col>
        </Row>
      </Col>
      <Col xs={12}>
        <Row
          className={classNames('containerSubHeader align-items-center', {
            isSmall: isSmall === true
          })}
        >
          <Col className="titleHeader">{title}</Col>
        </Row>
      </Col>
    </Row>
  );
}

HeaderCashier.propTypes = {
  title: PropTypes.any,
  isSmall: PropTypes.any,
  hideProfil: PropTypes.any
};
