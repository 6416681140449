import React, { Fragment, useEffect, useState } from 'react';
import wave from 'assets/img/wave.png';
import { Badge, Button, Card, Col, Modal, Row, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import * as Moment from 'moment/moment';
import Avatar from '../../../common/Avatar';
import SoftBadge from '../../../common/SoftBadge';
import { useNavigate, useParams } from 'react-router';
import HeaderCashier from '../header';
import {
  PutTicket,
  ShowTicketCashier
} from '../../../../services/TicketService';

export default function ShowCashierPageTicket() {
  const [show, setShow] = useState(false);
  const [selectedFormula, setSelectedFormula] = useState({});
  const [object, setObject] = useState([]);
  const { uuid } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const formatter = new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR'
  });

  const navigate = useNavigate();

  useEffect(() => {
    const fetchObject = async () => {
      const responseObject = await ShowTicketCashier(uuid);
      if (responseObject.success === true) {
        setObject(responseObject.data);
      } else if (responseObject.success === false && responseObject.message) {
        alert(responseObject.message);
        window.location.href = '/caissiers/accueil';
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    fetchObject();
  }, []);

  const handleSubmit = async () => {
    setIsLoading(true);
    const response = await PutTicket(
      {
        formula_id: selectedFormula.id
      },
      object.uuid
    );
    if (response.data && response.data.uuid) {
      navigate(`/caissiers/accueil`);
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
  };

  return (
    <Row>
      <Col xs={12} className="px-0">
        <HeaderCashier
          isSmall={true}
          title={
            <>
              {object.created_at && (
                <Fragment>
                  Ticket{' '}
                  {Moment(object.created_at).local().format('DD/MM/YYYY')}
                </Fragment>
              )}
            </>
          }
        />
        <img src={wave} alt="picture-wave" className="wavePicture" />
      </Col>
      {isLoading === true ? (
        <Col xs={12} className="mt-3">
          <Spinner animation="border" role="status" size="xs">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </Col>
      ) : (
        <>
          {object && object.uuid && object.user && (
            <Col xs={12}>
              <Row className="justify-content-center">
                <Col xs={12}>
                  <Row className="align-items-center">
                    <Col xs={2} className="mt-1">
                      <Button
                        className="align-items-center px-2 py-2"
                        onClick={() => {
                          window.location.href = '/caissiers/accueil';
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                        >
                          <path fill="none" d="M0 0h24v24H0z" />
                          <path
                            d="M10.828 12l4.95 4.95-1.414 1.414L8 12l6.364-6.364 1.414 1.414z"
                            fill="rgba(255,255,255,1)"
                          />
                        </svg>
                      </Button>
                    </Col>
                    <Col
                      xs={8}
                      className="mt-1"
                      onClick={() => {
                        setShow(true);
                      }}
                    >
                      <h2>{`${object.user.lastname} ${object.user.firstname}`}</h2>

                      <h3 className="pt-0 d-block" style={{ fontSize: '18px' }}>
                        {object.user.company?.name}
                        {object.user.registration_number && (
                          <Fragment>
                            {' '}
                            - {object.user.registration_number}
                          </Fragment>
                        )}
                      </h3>
                    </Col>
                  </Row>
                </Col>
                <Col xs={11} className="mt-2">
                  <Avatar
                    src={object.user.profile_picture_url}
                    rounded="circle"
                    size="5xl"
                  />
                </Col>
                <Col xs={11} className="mt-2 mb-2">
                  <b>Choisir une formule :</b>
                </Col>
                {object.user.company?.formulas &&
                  object.user.company?.formulas.length > 0 && (
                    <Col xs={11} className="mt-2">
                      <Row className="justify-content-center">
                        {object.user.company?.formulas.map((formula, index) => (
                          <Col key={index} xs={6} className="px-2">
                            <Card
                              bg="light"
                              text="dark"
                              onClick={() => {
                                setSelectedFormula(formula);
                                setShow(true);
                              }}
                            >
                              <Card.Body className="px-0 pb-2 pt-0">
                                <Card.Title
                                  as="div"
                                  className="mb-0 pt-1"
                                  style={{
                                    fontSize: '17px',
                                    lineHeight: '17px'
                                  }}
                                >
                                  <small>{formula.name}</small>
                                </Card.Title>
                                <SoftBadge bg="primary">
                                  Participation :{' '}
                                  {formatter.format(formula.calculated_value)}
                                </SoftBadge>
                              </Card.Body>
                            </Card>
                          </Col>
                        ))}
                      </Row>
                    </Col>
                  )}
              </Row>
              <Modal
                show={show}
                onHide={() => {
                  setShow(false);
                }}
                backdrop="static"
                keyboard={false}
              >
                {selectedFormula.id && (
                  <>
                    <Modal.Header>
                      <Modal.Title>Confirmer la remise ?</Modal.Title>
                    </Modal.Header>
                    <Row className="justify-content-center">
                      <Col xs={11} className="pt-3">
                        1 - Je renseigne la participation dans mon système :{' '}
                        <br />
                        <Badge
                          pill
                          bg="primary"
                          className="mt-2"
                          style={{ fontSize: '18px' }}
                        >
                          {formatter.format(selectedFormula.calculated_value)}
                        </Badge>{' '}
                        pour :
                        <br />
                        <Badge
                          pill
                          bg="primary"
                          className="mt-2"
                          style={{ fontSize: '18px' }}
                        >{`${object.user.lastname} ${object.user.firstname}`}</Badge>{' '}
                        {object.user.registration_number && (
                          <Fragment>
                            (matricule {object.user.registration_number})
                          </Fragment>
                        )}
                        <br />
                        <Badge
                          pill
                          bg="primary"
                          className="mt-2"
                          style={{ fontSize: '18px' }}
                        >
                          {object.user.company?.name}
                        </Badge>
                      </Col>
                      <Col xs={11} className="mt-3 mb-3">
                        2 - <b>OBLIGATOIRE</b> Je valide le ticket en cliquant
                        sur le bouton <b>Valider</b> ci-dessous.
                      </Col>
                    </Row>
                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        onClick={() => {
                          window.location.reload();
                        }}
                      >
                        Annuler
                      </Button>
                      <Button variant="primary" onClick={handleSubmit}>
                        Valider le ticket (définitif)
                      </Button>
                    </Modal.Footer>
                  </>
                )}
              </Modal>
            </Col>
          )}
        </>
      )}
    </Row>
  );
}
