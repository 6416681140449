import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Row, Col, Card } from 'react-bootstrap';
import Avatar from '../../../common/Avatar';
import HeaderEmployee from '../header';
import wave from '../../../../assets/img/wave.png';
import IconAlert from '../../../common/IconAlert';
import { LogOutUserService } from '../../../../services/AuthService';
import { useOutletContext } from 'react-router-dom';

const EmployeeProfile = ({ hasLabel }) => {
  const [user] = useOutletContext();
  return (
    <Row>
      <Col xs={12} className="px-0">
        <HeaderEmployee title="Mon profil" />
        <img src={wave} alt="picture-wave" className="wavePicture" />
      </Col>
      <Col xs={12}>
        <Row className="justify-content-center mb-4">
          <Col xs={12}>
            <Card className="customCard pt-3 text">
              <Row className="align-items-center">
                <Col xs={4} className="mt-1">
                  <Button
                    className="align-items-center px-2 py-2"
                    onClick={() => {
                      window.location.href = '/salaries/accueil';
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        d="M10.828 12l4.95 4.95-1.414 1.414L8 12l6.364-6.364 1.414 1.414z"
                        fill="rgba(255,255,255,1)"
                      />
                    </svg>
                  </Button>
                </Col>
                <Col xs={4} className="mt-2">
                  <Avatar
                    src={user.profile_picture_url}
                    className="avatar-profile-pic"
                    size="4xl"
                  />
                </Col>
              </Row>
              <Row className="justify-content-center align-items-center">
                <Col xs={12}>
                  <Card.Title className="mt-3 customTitleProfil">
                    Ma photo
                  </Card.Title>
                </Col>
                <Col xs={12} className="ps-5 pe-5 mt-2">
                  <Card.Text className="customTextBottomTitle">
                    Permet votre identification lors du passage en caisse.
                  </Card.Text>
                </Col>
                <Col xs={11} className="mt-2">
                  <IconAlert variant="info">
                    <p className="mb-0 text-start" style={{ fontSize: '12px' }}>
                      Veuillez contacter un administrateur du Ciarus pour
                      modifier votre photo.
                    </p>
                  </IconAlert>
                </Col>
                <Col md="12" className="mt-2 mb-1">
                  <div className="border-dashed-bottom"></div>
                </Col>
                <Col xs={12}>
                  <Card.Title className="mt-3 customTitleProfil">
                    Mes informations
                  </Card.Title>
                </Col>
                <Col xs={11} className="mt-3 mb-2">
                  <Form>
                    <Form.Group className="mb-3 text-start">
                      <Form.Label>Prénom</Form.Label>
                      <Form.Control
                        className="customInput p-2"
                        placeholder="Nom"
                        value={user.lastname || ''}
                        name="lastname"
                        disabled={true}
                        type="text"
                      />
                    </Form.Group>

                    <Form.Group className="mb-3 text-start">
                      <Form.Label>Prénom</Form.Label>
                      <Form.Control
                        className="customInput p-2"
                        placeholder="Prénom"
                        value={user.firstname || ''}
                        name="firstname"
                        disabled={true}
                        type="text"
                      />
                    </Form.Group>

                    <Form.Group className="mb-3 text-start">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        className="customInput p-2"
                        placeholder="Email"
                        value={user.email || ''}
                        name="email"
                        disabled={true}
                        type="email"
                      />
                    </Form.Group>

                    <Form.Group className="mb-3 text-start">
                      <Form.Label>Matricule</Form.Label>
                      <Form.Control
                        className="customInput p-2"
                        placeholder={!hasLabel ? 'Matricule' : ''}
                        value={user.registration_number || ''}
                        disabled={true}
                        name="registration_number"
                        type="text"
                      />
                    </Form.Group>
                    {user.salary_coefficient && (
                      <Form.Group className="mb-3 text-start">
                        <Form.Label>Indice (coef.) salarial</Form.Label>
                        <Form.Control
                          className="customInput p-2"
                          placeholder={
                            !hasLabel ? 'Indice (coef.) salarial' : ''
                          }
                          value={user.salary_coefficient}
                          name="salary_coefficient"
                          disabled={true}
                          type="number"
                        />
                      </Form.Group>
                    )}
                  </Form>
                </Col>
                <Col xs={11}>
                  <IconAlert variant="info">
                    <p className="mb-0 text-start" style={{ fontSize: '12px' }}>
                      Veuillez contacter un administrateur du Ciarus pour
                      modifier vos informations.
                    </p>
                  </IconAlert>
                </Col>
                <Col md="12" className="mt-2 mb-1">
                  <div className="border-dashed-bottom"></div>
                </Col>
                <Col xs={11} className="mb-3 text-start pt-3">
                  <Button
                    variant="falcon-default"
                    onClick={() => {
                      LogOutUserService();
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="18"
                      height="18"
                      style={{ marginBottom: '2px', marginRight: '4px' }}
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M4 18h2v2h12V4H6v2H4V3a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3zm2-7h7v2H6v3l-5-4 5-4v3z" />
                    </svg>
                    Me déconnecter
                  </Button>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

EmployeeProfile.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

EmployeeProfile.defaultProps = {
  layout: 'simple',
  hasLabel: false
};

export default EmployeeProfile;
