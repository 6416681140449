import React, { useEffect, useState } from 'react';
import wave from 'assets/img/wave.png';
import { Button, Col, Offcanvas, Row, Spinner } from 'react-bootstrap';
import * as Moment from 'moment/moment';
import HeaderCashier from '../header';
import { QrReader } from 'react-qr-reader';
import Lottie from 'react-lottie-player';
import phone from '../../../../assets/img/animated-icons/phone.json';
import { isAndroid, isIOS } from 'react-device-detect';
import { PutUser } from '../../../../services/UserService';
import { toast } from 'react-toastify';
import { useOutletContext } from 'react-router-dom';
import IconAlert from '../../../common/IconAlert';
import { ShowTicketCashier } from '../../../../services/TicketService';
import { useNavigate } from 'react-router';
import { LogOutUserService } from '../../../../services/AuthService';

export default function ScanTicket() {
  const [user] = useOutletContext();
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showButton, setShowButton] = useState(true);
  const [showCanvas, setShowCanvas] = useState(false);

  useEffect(() => {
    if (user.hide_mobile_popup !== 1) {
      setShowCanvas(true);
    }
  }, []);

  const navigate = useNavigate();

  const handleSubmitCanvas = async () => {
    setShowCanvas(false);
    const response = await PutUser('profile.hide_mobile_popup', user.id, {});
    if (!response.data) {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
  };

  const handleSubmit = async code => {
    setIsLoading(true);
    setError(false);
    const response = await ShowTicketCashier(code);
    if (response.success === true && response.data && response.data.uuid) {
      navigate(`/caissiers/ticket/${response.data.uuid}`);
    } else if (response.success === false && response.message) {
      setError(response.message);
      setShowButton(true);
      setIsLoading(false);
    } else {
      setError('QR Code invalide');
      setShowButton(true);
      setIsLoading(false);
    }
  };

  function isUUID(uuid) {
    let s = '' + uuid;

    s = s.match(
      '^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$'
    );
    if (s === null) {
      return false;
    }
    return true;
  }

  return (
    <Row>
      <Col xs={12} className="px-0">
        <HeaderCashier
          title={
            <>
              Scan de ticket
              <br />
              <small>{Moment(Date.now()).local().format('DD/MM/YYYY')}</small>
            </>
          }
        />
        <img src={wave} alt="picture-wave" className="wavePicture" />
      </Col>
      <Col xs={12}>
        <Row>
          {isLoading === true ? (
            <Col xs={12} className="mt-3">
              <Spinner animation="border" role="status" size="xs">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </Col>
          ) : showButton ? (
            <Col xs={12} className="mt-3">
              {error && (
                <Col xs={12}>
                  <IconAlert
                    variant="danger"
                    dismissible
                    onClose={() => setError(false)}
                  >
                    <p className="mb-0">{error}</p>
                  </IconAlert>
                </Col>
              )}
              <Button
                className="align-items-center mt-3 w-100 py-3"
                style={{ fontSize: '18px' }}
                onClick={() => {
                  setShowButton(false);
                  setError('');
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="20"
                  height="20"
                  style={{ marginBottom: '2px', marginRight: '3px' }}
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    d="M16 17v-1h-3v-3h3v2h2v2h-1v2h-2v2h-2v-3h2v-1h1zm5 4h-4v-2h2v-2h2v4zM3 3h8v8H3V3zm2 2v4h4V5H5zm8-2h8v8h-8V3zm2 2v4h4V5h-4zM3 13h8v8H3v-8zm2 2v4h4v-4H5zm13-2h3v2h-3v-2zM6 6h2v2H6V6zm0 10h2v2H6v-2zM16 6h2v2h-2V6z"
                    fill="rgba(255,255,255,1)"
                  />
                </svg>
                Scanner un nouveau code
              </Button>
            </Col>
          ) : (
            <Col xs={12}>
              <Row className="pb-7 justify-content-center">
                {error && (
                  <Col xs={12}>
                    <IconAlert
                      variant="danger"
                      dismissible
                      onClose={() => setError(false)}
                    >
                      <p className="mb-0">{error}</p>
                    </IconAlert>
                  </Col>
                )}
                <Col xs={12} className="qrreader">
                  <QrReader
                    constraints={{ facingMode: 'environment' }}
                    onResult={(result, error) => {
                      if (result) {
                        if (!isUUID(result?.text)) {
                          setError('Code invalide');
                        } else {
                          handleSubmit(result.text);
                        }
                      }
                      if (error) {
                        //console.info(error);
                      }
                    }}
                    style={{ width: '100%', paddingTop: '0' }}
                  />
                </Col>
                <Col xs={12} className="mb-3 text-center pt-3">
                  <Button
                    variant="falcon-default"
                    onClick={() => {
                      window.location.reload();
                    }}
                  >
                    Recharger la caméra
                  </Button>
                </Col>
              </Row>
            </Col>
          )}
          <Col xs="12" className="mt-7 mb-3">
            <div className="border-dashed-bottom"></div>
          </Col>
          <Col xs={12} className="mb-3 text-center pt-0">
            <Button
              variant="falcon-default"
              onClick={() => {
                LogOutUserService();
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="18"
                height="18"
                style={{ marginBottom: '2px', marginRight: '4px' }}
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M4 18h2v2h12V4H6v2H4V3a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3zm2-7h7v2H6v3l-5-4 5-4v3z" />
              </svg>
              Me déconnecter
            </Button>
          </Col>
        </Row>
      </Col>
      <Offcanvas
        show={showCanvas}
        placement="bottom"
        onHide={() => {
          handleSubmitCanvas();
        }}
      >
        <Offcanvas.Header closeButton>
          Gagnez du temps la prochaine fois !
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row className="align-items-center">
            <Col xs={3}>
              <Lottie
                loop={true}
                animationData={phone}
                play={true}
                style={{
                  width: '80px',
                  height: '100%',
                  margin: '0 auto'
                }}
              />
            </Col>
            <Col xs={9}>
              <b className="d-block mb-2">
                Ajoutez le site sur l'accueil de votre téléphone !
              </b>
              {isIOS ? (
                <div className="d-block">
                  En bas de votre navigateur, cliquez sur l'icône de partage{' '}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="20"
                    height="20"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M4 19h16v-7h2v8a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-8h2v7zm9-10v7h-2V9H6l6-6 6 6h-5z" />
                  </svg>{' '}
                  puis sur « <b>Sur l'écran d'accueil</b> ».
                </div>
              ) : isAndroid ? (
                <div className="d-block">
                  Cliquez sur les trois petits points présents en haut et à
                  droite de l’écran, puis appuyez sur « Ajouter à l’écran
                  d’accueil ».
                </div>
              ) : (
                ''
              )}
            </Col>
          </Row>
        </Offcanvas.Body>
      </Offcanvas>
    </Row>
  );
}
