import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';
import RequireAuthEmployee from '../../../middlewares/RequireAuthEmployee';
import Section from '../../common/Section';
import { toast } from 'react-toastify';
import { ShowUser } from '../../../services/UserService';

const EmployeeLayout = () => {
  const [user, setUser] = useState({});
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchObject = async () => {
      const responseObject = await ShowUser(
        JSON.parse(localStorage.getItem('user')).id
      );
      if (responseObject.success === true) {
        localStorage.setItem('user', JSON.stringify(responseObject.data));
        setUser(responseObject.data);
        RequireAuthEmployee(responseObject.data, navigate);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    if (localStorage.getItem('user')) {
      fetchObject();
    } else {
      toast('Veuillez vous connecter pour accéder au contenu');
      navigate('/login');
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Section className="py-0 text-center backgroundLayoutCustomColorWhite">
        {user.id && <Outlet context={[user, setUser]} />}
      </Section>
      {/*<BrowserView>
        <Section>
          <Row className="align-items-center justify-content-center pt-3 pb-1">
            <Col xs={12} className="text-center">
              <img
                src={logoCiarusRestaurant}
                alt="Logo Ciarus-restaurant"
                className="cursor-pointer"
                onClick={() => {
                  window.location.href = '/salaries/accueil';
                }}
                style={{ maxWidth: '250px' }}
              />
            </Col>
            <Col xs={12} className="text-center mt-4">
              <h4>
                La plateforme de restauration du CIARUS est uniquement
                disponible sur mobile.
              </h4>
            </Col>
            <Col xs={8} className="text-center mt-4">
              <h4>
                Rendez-vous sur votre téléphone en scannant le QR code suivant :
              </h4>
            </Col>
            <Col xs={12}></Col>
            <Col xs={2} className="text-center mt-4">
              <QRCode
                size={256}
                style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                value="https://restaurant.ciarus.com"
                viewBox={`0 0 256 256`}
              />
            </Col>
            <Col xs={11} className="mb-3 text-center pt-3 mt-7">
              <Button
                variant="falcon-default"
                onClick={() => {
                  LogOutUserService();
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                  style={{ marginBottom: '2px', marginRight: '4px' }}
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M4 18h2v2h12V4H6v2H4V3a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3zm2-7h7v2H6v3l-5-4 5-4v3z" />
                </svg>
                Me déconnecter
              </Button>
            </Col>
          </Row>
        </Section>
      </BrowserView>
      <MobileView>
        <Section className="py-0 text-center backgroundLayoutCustomColorWhite">
          {user.id && <Outlet context={[user, setUser]} />}
        </Section>
      </MobileView>*/}
    </>
  );
};

export default EmployeeLayout;
