import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Alert, Button, Form } from 'react-bootstrap';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ForgotPasswordResetService } from '../../../../services/AuthService';
import FormAlert from '../../commons/FormAlert';

const PasswordResetCiarus = () => (
  <div className="text-center">
    <h5>Réinitialiser mon mot de passe</h5>
    <PasswordResetForm />
  </div>
);

const PasswordResetForm = ({ hasLabel }) => {
  const [searchParams] = useSearchParams();

  // State
  const [formData, setFormData] = useState({
    token: searchParams.get('token'),
    email: searchParams.get('email'),
    password: '',
    password_confirmation: ''
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errorForm, setErrorForm] = useState(false);
  const navigate = useNavigate();

  // Handler
  const handleSubmit = async e => {
    e.preventDefault();
    setIsLoading(true);
    const response = await ForgotPasswordResetService(formData);
    if (response.success === true) {
      navigate(`/login?email=${formData.email}`);
      toast(
        'Mot de passe réinitialisé, vous pouvez maintenant vous connecter.'
      );
    } else if (response.validator) {
      setErrorForm(response.validator);
      setIsLoading(false);
    } else {
      setErrorForm('Lien et/ou email non reconnu');
      setIsLoading(false);
    }
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  if (searchParams.get('token')) {
    return (
      <Form
        className={classNames('mt-3', { 'text-left': hasLabel })}
        onSubmit={handleSubmit}
      >
        {errorForm && <FormAlert variant="danger" data={errorForm} />}
        <Form.Control name="token" type="hidden" value={formData.token} />
        <Form.Group className="mb-3">
          {hasLabel && <Form.Label>Adresse email</Form.Label>}
          <Form.Control
            placeholder={!hasLabel ? 'Adresse email' : ''}
            value={formData.email}
            name="email"
            onChange={handleFieldChange}
            type="email"
          />
        </Form.Group>

        <Form.Group className="mb-3">
          {hasLabel && <Form.Label>Nouveau mot de passe</Form.Label>}
          <Form.Control
            placeholder={!hasLabel ? 'Nouveau mot de passe' : ''}
            name="password"
            onChange={handleFieldChange}
            type="password"
          />
        </Form.Group>

        <Form.Group className="mb-3">
          {hasLabel && (
            <Form.Label>Confirmation du nouveau mot de passe</Form.Label>
          )}
          <Form.Control
            placeholder={
              !hasLabel ? 'Confirmation du nouveau mot de passe' : ''
            }
            name="password_confirmation"
            onChange={handleFieldChange}
            type="password"
          />
        </Form.Group>

        <Button
          type="submit"
          className="w-100"
          disabled={
            !formData.password || !formData.password_confirmation || isLoading
          }
        >
          Enregistrer mon mot de passe
        </Button>
      </Form>
    );
  } else {
    return <Alert variant="error">Une erreur est survenue.</Alert>;
  }
};

PasswordResetForm.propTypes = {
  hasLabel: PropTypes.bool
};

export default PasswordResetCiarus;
