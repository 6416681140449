import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ExcelSheet from 'react-export-excel/dist/ExcelPlugin/elements/ExcelSheet';
import ExcelColumn from 'react-export-excel/dist/ExcelPlugin/elements/ExcelColumn';
import ExcelFile from 'react-export-excel/dist/ExcelPlugin/components/ExcelFile';
import IconButton from '../components/common/IconButton';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';

const ExportEmployees = ({ users }) => {
  const [cleanData, setCleanData] = useState([]);
  let tmpCleanData = [];
  useEffect(() => {
    users.map(user => {
      let cleanedCode = {
        firstname: user.firstname,
        lastname: user.lastname,
        active: user.active_toString,
        company: user.company_toString,
        registration_number: user.registration_number_toString,
        salary_coefficient: user.salary_coefficient_toString,
        email: user.emailOriginal,
        phone: user.phone,
        created_at: user.created_at,
        last_login_at: user.last_login_at
      };
      tmpCleanData.push(cleanedCode);
    });
    setCleanData(tmpCleanData);
  }, [users]);
  return (
    <>
      {cleanData.length > 0 && (
        <>
          <ExcelFile
            element={
              <IconButton
                className="mb-1 w-auto float-end"
                variant="falcon-default"
                size="sm"
                icon={faFileExcel}
                transform="shrink-3"
              >
                Exporter la liste
              </IconButton>
            }
          >
            <ExcelSheet data={cleanData} name="Membres">
              <ExcelColumn label="Prénom" value="firstname" />
              <ExcelColumn label="Nom" value="lastname" />
              <ExcelColumn label="Actif" value="active" />
              <ExcelColumn label="Société" value="company" />
              <ExcelColumn label="Matricule" value="registration_number" />
              <ExcelColumn label="Indice" value="salary_coefficient" />
              <ExcelColumn label="Email" value="email" />
              <ExcelColumn label="Téléphone" value="phone" />
              <ExcelColumn label="Date d'ajout" value="created_at" />
              <ExcelColumn label="Dernière connexion" value="last_login_at" />
            </ExcelSheet>
          </ExcelFile>
        </>
      )}
    </>
  );
};

ExportEmployees.propTypes = {
  users: PropTypes.array
};

export default ExportEmployees;
