import React, { useContext, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import is from 'is_js';
import Landing from 'components/pages/landing/Landing';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton } from 'components/common/Toast';

import AppContext from 'context/Context';
import LoginCiarus from '../components/ciarus/pages/authentication/Login';
import ForgetPasswordCiarus from '../components/ciarus/pages/authentication/ForgetPassword';
import PasswordResetCiarus from '../components/ciarus/pages/authentication/PasswordReset';
import AuthSimpleLayoutCiarus from '../components/ciarus/layouts/AuthSimpleLayout';
import AdminLayout from '../components/ciarus/layouts/AdminLayout';
import EmployeeLayout from '../components/ciarus/layouts/EmployeeLayout';
import AccountantLayout from '../components/ciarus/layouts/AccountantLayout';
import CashierLayout from '../components/ciarus/layouts/CashierLayout';
import IndexCompanies from '../components/ciarus/admin/companies';
import ManageCompanies from '../components/ciarus/admin/companies/manage';
import IndexEmployees from '../components/ciarus/admin/users/employees/indexEmployees';
import ManageEmployees from '../components/ciarus/admin/users/employees/manage';
import Error404 from '../components/ciarus/errors/Error404';
import Error500 from '../components/ciarus/errors/Error500';
import ErrorLayout from '../components/ciarus/layouts/ErrorLayout';
import IndexCashiers from '../components/ciarus/admin/users/cashiers/indexCashiers';
import ManageCashiers from '../components/ciarus/admin/users/cashiers/manage';
import IndexAccountants from '../components/ciarus/admin/users/accountants/indexAccountants';
import ManageAccountants from '../components/ciarus/admin/users/accountants/manage';
import IndexAdmins from '../components/ciarus/admin/users/admins/indexAdmins';
import ManageAdmins from '../components/ciarus/admin/users/admins/manage';
import IndexTickets from '../components/ciarus/accountant/tickets/indexTickets';
import GenerateTicket from '../components/ciarus/employee/tickets/generate';
import ShowPageTicket from '../components/ciarus/employee/tickets/show';
import EmployeeProfilPicture from '../components/ciarus/employee/profile/profilePicture';
import EmployeeProfile from '../components/ciarus/employee/profile/profile';
import EmployeeProfilCoefficient from '../components/ciarus/employee/profile/profileCoefficient';
import { Navigate } from 'react-router';
import ScanTicket from '../components/ciarus/cashier/tickets/scan';
import ShowCashierPageTicket from '../components/ciarus/cashier/tickets/show';

const Layout = () => {
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  useContext(AppContext);

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
  }, [HTMLClassList]);

  return (
    <>
      <Routes>
        <Route element={<AuthSimpleLayoutCiarus />}>
          <Route path="/" element={<LoginCiarus />} />
          <Route path="login" element={<LoginCiarus />} />
          <Route path="forgot-password" element={<ForgetPasswordCiarus />} />
          <Route path="reset-password" element={<PasswordResetCiarus />} />
        </Route>

        <Route element={<AdminLayout />}>
          <Route
            path="/administration/entreprises"
            element={<IndexCompanies />}
          />
          <Route
            path="/administration/entreprises/:id/modifier"
            element={<ManageCompanies />}
          />
          <Route path="/administration/salaries" element={<IndexEmployees />} />
          <Route
            path="/administration/salaries/ajouter"
            element={<ManageEmployees />}
          />
          <Route
            path="/administration/salaries/:id/modifier"
            element={<ManageEmployees />}
          />
          <Route path="/administration/caissiers" element={<IndexCashiers />} />
          <Route
            path="/administration/caissiers/ajouter"
            element={<ManageCashiers />}
          />
          <Route
            path="/administration/caissiers/:id/modifier"
            element={<ManageCashiers />}
          />
          <Route
            path="/administration/comptables"
            element={<IndexAccountants />}
          />
          <Route
            path="/administration/comptables/ajouter"
            element={<ManageAccountants />}
          />
          <Route
            path="/administration/comptables/:id/modifier"
            element={<ManageAccountants />}
          />
          <Route path="/administration/admins" element={<IndexAdmins />} />
          <Route
            path="/administration/admins/ajouter"
            element={<ManageAdmins />}
          />
          <Route
            path="/administration/admins/:id/modifier"
            element={<ManageAdmins />}
          />
        </Route>
        <Route element={<AccountantLayout />}>
          <Route
            path="/comptabilite/tableau-de-bord"
            element={<IndexTickets />}
          />
        </Route>
        <Route element={<EmployeeLayout />}>
          <Route path="/salaries/accueil" element={<GenerateTicket />} />
          <Route path="/salaries/profil" element={<EmployeeProfile />} />
          <Route
            path="/salaries/profil/photo-de-profil"
            element={<EmployeeProfilPicture />}
          />
          <Route
            path="/salaries/profil/coefficient"
            element={<EmployeeProfilCoefficient />}
          />
          <Route path="/salaries/ticket/:uuid" element={<ShowPageTicket />} />
        </Route>
        <Route element={<CashierLayout />}>
          <Route path="/caissiers/accueil" element={<ScanTicket />} />
          <Route
            path="/caissiers/ticket/:uuid"
            element={<ShowCashierPageTicket />}
          />
        </Route>

        <Route path="landing" element={<Landing />} />
        <Route element={<ErrorLayout />}>
          <Route path="errors/404" element={<Error404 />} />
          <Route path="errors/500" element={<Error500 />} />
        </Route>
        <Route path="*" element={<Navigate to="/errors/404" replace />} />
      </Routes>
      <ToastContainer
        closeButton={CloseButton}
        icon={false}
        position={toast.POSITION.TOP_CENTER}
      />
    </>
  );
};

export default Layout;
