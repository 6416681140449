import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import logoCiarusRestaurant from 'assets/img/logo-ciarus-gourmet.png';
import avatar from 'assets/img/avatar.png';
import { useNavigate } from 'react-router';
import { useOutletContext } from 'react-router-dom';
import classNames from 'classnames';

export default function HeaderEmployee({ title, isSmall, hideProfil }) {
  const [user] = useOutletContext();
  let navigate = useNavigate();
  return (
    <Row>
      <Col xs={12}>
        <Row
          className="justify-content-center"
          style={{ backgroundColor: '#1c1c1d' }}
        >
          <Col xs={11}>
            <Row className="align-items-center pt-3 pb-1">
              <Col
                xs={6}
                className="text-start"
                style={{ backgroundColor: '#1c1c1d' }}
              >
                <img
                  src={logoCiarusRestaurant}
                  alt="Logo Ciarus-restaurant"
                  className="cursor-pointer"
                  onClick={() => {
                    window.location.href = '/salaries/accueil';
                  }}
                  style={{ maxWidth: '75px' }}
                />
              </Col>
              {hideProfil === true ? (
                <Col xs={6} />
              ) : (
                <Col xs={6} className="text-end containerHeaderImgProfil">
                  <img
                    onClick={() => {
                      navigate('/salaries/profil');
                    }}
                    src={
                      user.profile_picture_url
                        ? user.profile_picture_url
                        : avatar
                    }
                    alt="Picture avatar"
                    className="rounded-circle customAvatarInHeader cursor-pointer m-0"
                  />
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </Col>
      <Col xs={12}>
        <Row
          className={classNames('containerSubHeader align-items-center', {
            isSmall: isSmall === true
          })}
        >
          <Col className="titleHeader">{title}</Col>
        </Row>
      </Col>
    </Row>
  );
}

HeaderEmployee.propTypes = {
  title: PropTypes.any,
  isSmall: PropTypes.any,
  hideProfil: PropTypes.any
};
