import { toast } from 'react-toastify';

export default function RequireAuthCashier(user, navigate) {
  if (!localStorage.getItem('accessToken') && !user) {
    toast('Veuillez vous connecter pour accéder au contenu');
    navigate('/login');
  } else if (user.role_id !== 4) {
    toast("Vous n'êtes pas autorisé à accéder à cette page.");
    navigate('/');
  }
}
