import React, { Fragment, useEffect, useState } from 'react';
import wave from 'assets/img/wave.png';
import {
  Badge,
  Button,
  Card,
  Col,
  Modal,
  Offcanvas,
  Row
} from 'react-bootstrap';
import { useOutletContext } from 'react-router-dom';
import { PostTicket } from '../../../../services/TicketService';
import HeaderEmployee from '../header';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import * as Moment from 'moment/moment';
import Lottie from 'react-lottie-player';
import food from 'assets/img/animated-icons/food.json';
import code from 'assets/img/animated-icons/code.json';
import phone from 'assets/img/animated-icons/phone.json';
import IconAlert from '../../../common/IconAlert';
import { isIOS, isAndroid } from 'react-device-detect';
import { PutUser } from '../../../../services/UserService';

export default function GenerateTicket() {
  const [user] = useOutletContext();
  const [show, setShow] = useState(false);
  const [showCanvas, setShowCanvas] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  let navigate = useNavigate();

  useEffect(() => {
    if (user.hide_mobile_popup !== 1) {
      setShowCanvas(true);
    }
  }, []);

  const handleSubmit = async () => {
    setIsLoading(true);
    const response = await PostTicket();
    if (response.data && response.data.uuid) {
      navigate(`/salaries/ticket/${response.data.uuid}`);
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
  };

  const handleSubmitCanvas = async () => {
    setIsLoading(true);
    setShowCanvas(false);
    const response = await PutUser('profile.hide_mobile_popup', user.id, {});
    if (!response.data) {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
  };

  return (
    <Row>
      <Col xs={12} className="px-0">
        <HeaderEmployee
          title={
            <>
              Restaurant Ciarus
              <br />
              <small>
                {`${user.firstname} ${user.lastname}`} -{' '}
                {Moment(Date.now()).local().format('DD/MM/YYYY')}
              </small>
            </>
          }
        />
        <img src={wave} alt="picture-wave" className="wavePicture" />
      </Col>
      <Col xs={12}>
        <Row>
          <Col xs={12}>
            <Row className="pb-7 justify-content-center">
              {user.active === 0 && (
                <Col xs={12} className="mt-2">
                  <IconAlert variant="warning">
                    <p className="mb-0 text-start" style={{ fontSize: '14px' }}>
                      Veuillez vous présenter à l'accueil du CIARUS pour activer
                      votre compte et pouvoir générer votre ticket repas.
                    </p>
                  </IconAlert>
                </Col>
              )}
              <Fragment>
                <Col className="mt-1" xs={12}>
                  <Card bg="light" text="dark">
                    <Card.Body className="p-3">
                      <Card.Title
                        as="div"
                        className="mb-0"
                        style={{ fontSize: '17px' }}
                      >
                        <Badge pill bg="primary" className="me-2">
                          1
                        </Badge>
                        <span className="d-block mb-2"></span>
                        Je récupère mon ticket repas pour le déjeuner au Ciarus
                      </Card.Title>
                      <Button
                        type="submit"
                        className="align-items-center mt-3"
                        onClick={() => {
                          user.active !== 1 ? setShow(true) : handleSubmit();
                        }}
                        disabled={isLoading}
                      >
                        {user.active !== 1 && (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="18"
                            height="18"
                            style={{
                              marginBottom: '2px',
                              marginRight: '3px'
                            }}
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path
                              d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-5h2v2h-2v-2zm0-8h2v6h-2V7z"
                              fill="rgba(255,255,255,1)"
                            />
                          </svg>
                        )}
                        Mon ticket repas
                      </Button>
                    </Card.Body>
                  </Card>
                </Col>
                <Col xs={12} className="mt-3">
                  <Card bg="light" text="dark">
                    <Card.Body className="p-3">
                      <Row className="align-items-center">
                        <Col xs={12} className="ps-0 pe-0 text-center">
                          <Card.Title
                            as="div"
                            className="mb-0"
                            style={{ fontSize: '17px' }}
                          >
                            <Badge pill bg="primary" className="me-2">
                              2
                            </Badge>
                            <span className="d-block mb-2"></span>
                            Je vais me servir
                          </Card.Title>
                        </Col>
                        <Col xs={12} className="px-0 mt-3">
                          <Lottie
                            loop={true}
                            animationData={food}
                            play={true}
                            style={{
                              width: '80px',
                              height: '100%',
                              margin: '0 auto'
                            }}
                          />
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
                <Col xs={12} className="mt-3">
                  <Card bg="light" text="dark">
                    <Card.Body className="p-3">
                      <Row className="align-items-center">
                        <Col xs={12} className="ps-0 pe-0">
                          <Card.Title
                            as="div"
                            className="mb-0"
                            style={{ fontSize: '17px' }}
                          >
                            <Badge pill bg="primary" className="me-2">
                              3
                            </Badge>
                            <span className="d-block mb-2"></span>
                            Je présente mon ticket à la caisse
                          </Card.Title>
                        </Col>
                        <Col xs={12} className="px-0">
                          <Lottie
                            animationData={code}
                            play
                            loop={true}
                            style={{
                              width: '200px',
                              height: '100%',
                              margin: '0 auto'
                            }}
                          />
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Fragment>
            </Row>
          </Col>
        </Row>
      </Col>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <Col xs={12} className="mt-2">
            <IconAlert variant="warning">
              <p className="mb-0 text-start" style={{ fontSize: '14px' }}>
                Veuillez vous présenter à l'accueil du CIARUS pour activer votre
                compte et pouvoir générer votre ticket repas.
              </p>
            </IconAlert>
          </Col>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShow(false)}>Fermer</Button>
        </Modal.Footer>
      </Modal>
      <Offcanvas
        show={showCanvas}
        placement="bottom"
        onHide={() => {
          handleSubmitCanvas();
        }}
      >
        <Offcanvas.Header closeButton>
          Gagnez du temps la prochaine fois !
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row className="align-items-center">
            <Col xs={3}>
              <Lottie
                loop={true}
                animationData={phone}
                play={true}
                style={{
                  width: '80px',
                  height: '100%',
                  margin: '0 auto'
                }}
              />
            </Col>
            <Col xs={9}>
              <b className="d-block mb-2">
                Ajoutez le site sur l'accueil de votre téléphone !
              </b>
              {isIOS ? (
                <div className="d-block">
                  En bas de votre navigateur, cliquez sur l'icône de partage{' '}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="20"
                    height="20"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M4 19h16v-7h2v8a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-8h2v7zm9-10v7h-2V9H6l6-6 6 6h-5z" />
                  </svg>{' '}
                  puis sur « <b>Sur l'écran d'accueil</b> ».
                </div>
              ) : isAndroid ? (
                <div className="d-block">
                  Cliquez sur les trois petits points présents en haut et à
                  droite de l’écran, puis appuyez sur « Ajouter à l’écran
                  d’accueil ».
                </div>
              ) : (
                ''
              )}
            </Col>
          </Row>
        </Offcanvas.Body>
      </Offcanvas>
    </Row>
  );
}
