import { toast } from 'react-toastify';

export default function RequireAuthAdmin(navigate) {
  if (!localStorage.getItem('accessToken') && !localStorage.getItem('user')) {
    toast('Veuillez vous connecter pour accéder au contenu');
    navigate('/login');
  } else if (JSON.parse(localStorage.getItem('user')).role_id !== 1) {
    toast("Vous n'êtes pas autorisé à accéder à cette page.");
    navigate('/');
  }
}
