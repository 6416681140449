import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Button, Form, Row, Col, Card, Spinner, Modal } from 'react-bootstrap';
import { PutUser, ShowUser } from '../../../../services/UserService';
import HeaderEmployee from '../header';
import wave from '../../../../assets/img/wave.png';
import IconAlert from '../../../common/IconAlert';
import FormAlert from '../../commons/FormAlert';

const EmployeeProfilCoefficient = ({ hasLabel }) => {
  const [trigger] = useState(Date.now());
  const [errorForm, setErrorForm] = useState(false);
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState([]);
  const [formData, setFormData] = useState({
    salary_coefficient: ''
  });

  useEffect(() => {
    const fetchUser = async () => {
      const responseUser = await ShowUser(
        JSON.parse(localStorage.getItem('user')).id
      );
      if (responseUser.success === true) {
        if (!responseUser.data.salary_coefficient) {
          setUser(responseUser.data);
          setFormData({
            salary_coefficient: responseUser.data.salary_coefficient
          });
        } else {
          window.location.href = '/salaries/accueil';
        }
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    fetchUser();
  }, [trigger]);

  const handleSubmit = async e => {
    e.preventDefault();
    setShow(false);
    const response = await PutUser(
      'profile.salary_coefficient',
      user.id,
      formData
    );
    setErrorForm(response.validator);
    if (response.success === true) {
      setIsLoading(true);
      localStorage.setItem('user', JSON.stringify(response.data));
      setTimeout(function () {
        window.location.href = '/salaries/accueil';
      }, 1500);
    } else if (!response.validator) {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Row>
      <Col xs={12} className="px-0">
        <HeaderEmployee title="Initialiser mon compte" hideProfil={true} />
        <img src={wave} alt="picture-wave" className="wavePicture" />
      </Col>
      {isLoading === true ? (
        <Col xs={12} className="mt-3">
          <Spinner animation="border" role="status" size="xs">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </Col>
      ) : (
        <Col xs={12}>
          <Row className="justify-content-center">
            <Col xs={12}>
              <Card className="customCard pt-3 text">
                <Row className="justify-content-center">
                  <Col xs={12}>
                    <Card.Title className="mt-3 customTitleProfil">
                      Mon indice (coefficient salarial)
                    </Card.Title>
                  </Col>
                  <Col xs={12} className="px-4 mt-3 text-start">
                    <IconAlert variant="info">
                      <p className="mb-0" style={{ fontSize: '12px' }}>
                        Votre indice (coefficient salarial) est obligatoire et
                        permet de calculer la réduction de votre repas.
                      </p>
                    </IconAlert>
                  </Col>
                  <Col xs={12} className="px-4">
                    {errorForm && (
                      <FormAlert variant="danger" data={errorForm} />
                    )}
                  </Col>
                  <Col xs={11} className="mt-3 mb-5">
                    <Form>
                      <Form.Group className="mb-3 text-start">
                        <Form.Label>Indiquez votre indice :</Form.Label>
                        <Form.Control
                          className="customInput p-2"
                          placeholder={
                            !hasLabel ? 'Indice (coefficient salarial)' : ''
                          }
                          value={formData.salary_coefficient}
                          name="salary_coefficient"
                          onChange={handleFieldChange}
                          type="number"
                        />
                      </Form.Group>

                      <Button
                        color="primary"
                        className="w-100 mt-3 px-4"
                        disabled={isLoading}
                        onClick={() => {
                          window.scrollTo({ top: 0, behavior: 'smooth' });
                          setShow(true);
                        }}
                      >
                        Enregistrer mon indice
                      </Button>
                    </Form>
                  </Col>
                  <Modal
                    show={show}
                    onHide={() => {
                      setShow(false);
                    }}
                    backdrop="static"
                    keyboard={false}
                  >
                    <Modal.Header>
                      <Modal.Title>Confirmer l'indice ?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      L'enregistrement de votre indice est définitif. Pour
                      modifier votre indice plus tard, vous devrez contacter un
                      administrateur du Ciarus.
                      <br />
                      <b>Confirmez-vous ?</b>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        onClick={() => {
                          window.location.reload();
                        }}
                      >
                        Modifier
                      </Button>
                      <Button variant="primary" onClick={handleSubmit}>
                        Oui, je confirme
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </Row>
              </Card>
            </Col>
          </Row>
        </Col>
      )}
    </Row>
  );
};

EmployeeProfilCoefficient.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

EmployeeProfilCoefficient.defaultProps = {
  layout: 'simple',
  hasLabel: false
};

export default EmployeeProfilCoefficient;
