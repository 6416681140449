import React, { Fragment, useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  InputGroup,
  Modal,
  Offcanvas,
  OverlayTrigger,
  Row,
  Table,
  Tooltip
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useParams } from 'react-router';
import { ShowCompany } from '../../../../services/CompanyService';
import FormAlert from '../../commons/FormAlert';
import SoftBadge from '../../../common/SoftBadge';
import IconAlert from '../../../common/IconAlert';
import {
  DeleteFormula,
  PostFormula,
  PutFormula
} from '../../../../services/FormulaService';
import classNames from 'classnames';
import {
  DeleteCondition,
  PostCondition,
  PutCondition
} from '../../../../services/ConditionService';
import { IndexOperator } from '../../../../services/OperatorService';
import ManageCompany from './manageCompany';
import ManageDeleteCompany from './manageDeleteCompany';

const ManageCompanies = () => {
  const { id } = useParams();
  const [trigger, setTrigger] = useState(Date.now());
  const [object, setObject] = useState({});
  const [operators, setOperators] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorForm, setErrorForm] = useState(false);
  const [errorFormCondition, setErrorFormCondition] = useState(false);
  const [successForm, setSuccessForm] = useState(false);
  const [selectedObject, setSelectedObject] = useState({});
  const [showManage, setShowManage] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showDeleteCompany, setShowDeleteCompany] = useState(false);
  const [showManageCompany, setShowManageCompany] = useState(false);
  const [showManageConditions, setShowManageConditions] = useState(false);
  const [showManageCondition, setShowManageCondition] = useState(false);
  const [selectedCondition, setSelectedCondition] = useState({});
  const [formData, setFormData] = useState({
    name: '',
    fixed_value: '',
    company_id: id
  });
  const [formDataCondition, setFormDataCondition] = useState({
    formula_id: '',
    operator_id: '',
    value: '',
    result: ''
  });

  useEffect(() => {
    const fetchObject = async () => {
      const responseObject = await ShowCompany(id);
      if (responseObject.success === true) {
        setObject(responseObject.data);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
      if (selectedObject.id && responseObject.data.formulas) {
        setSelectedObject(
          responseObject.data.formulas.filter(
            f => f.id === selectedObject.id
          )[0]
        );
      }
    };
    const fetchOperators = async () => {
      const responseObject = await IndexOperator();
      if (responseObject.success === true) {
        setOperators(responseObject.data);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    fetchObject();
    fetchOperators();
  }, [trigger]);

  const handleSubmit = async e => {
    e.preventDefault();
    setErrorForm(false);
    setIsLoading(true);
    let response = false;
    if (selectedObject.id !== null && selectedObject.id !== undefined) {
      response = await PutFormula(selectedObject.id, formData);
    } else {
      response = await PostFormula(formData);
    }
    setSuccessForm(false);
    setErrorForm(response.validator);
    setIsLoading(false);
    if (response.success === true) {
      const successMessage = `Formule enregistrée.`;
      setShowManage(false);
      setSelectedObject({});
      setFormData({
        name: '',
        fixed_value: '',
        company_id: id
      });
      toast(successMessage);
      setTrigger(Date.now());
    }
  };

  const handleConditionSubmit = async e => {
    e.preventDefault();
    setErrorFormCondition(false);
    setIsLoading(true);
    let response = false;
    if (selectedCondition.id) {
      response = await PutCondition(selectedCondition.id, formDataCondition);
    } else {
      response = await PostCondition(formDataCondition);
    }
    setErrorFormCondition(response.validator);
    setIsLoading(false);
    if (response.success === true) {
      const successMessage = `Condition enregistrée.`;
      toast(successMessage);
      setTrigger(Date.now());
      setShowManageCondition(false);
    }
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleFieldConditionChange = e => {
    setFormDataCondition({
      ...formDataCondition,
      [e.target.name]: e.target.value
    });
  };

  const deleteFormula = async () => {
    const response = await DeleteFormula(selectedObject.id);
    if (response.success === true) {
      setShowDelete(false);
      setShowManage(false);
      setSelectedObject({});
      setFormData({
        name: '',
        fixed_value: '',
        company_id: id
      });
      toast('Formule archivée');
      setTrigger(Date.now());
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
  };

  const deleteCondition = async id => {
    const response = await DeleteCondition(id);
    if (response.success === true) {
      setShowManageCondition(false);
      toast('Condition archivée');
      setTrigger(Date.now());
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
  };

  return (
    <>
      {id && object.id !== null && object.id !== undefined && (
        <>
          {showManageCompany && (
            <ManageCompany
              showManageCompany={showManageCompany}
              setShowManageCompany={setShowManageCompany}
              setparentTrigger={setTrigger}
              object={object}
            />
          )}

          {showDeleteCompany && (
            <ManageDeleteCompany
              object={object}
              showDeleteCompany={showDeleteCompany}
              setShowDeleteCompany={setShowDeleteCompany}
            />
          )}
          <Form>
            <Row className="g-3">
              <Col xs={12}>
                <Card>
                  <Card.Body>
                    <Row className="flex-between-center">
                      <Col md>
                        <h3 className="mb-2 mb-md-0 text-primary admin-title mb-0">
                          {object.name} - Gérer les formules
                        </h3>
                      </Col>
                      <Col xs="auto">
                        <Button
                          size="sm"
                          variant="falcon-default"
                          className="me-2"
                          onClick={() => {
                            window.location.href =
                              '/administration/entreprises';
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="20"
                            height="20"
                            style={{ marginBottom: '2px' }}
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M10.828 12l4.95 4.95-1.414 1.414L8 12l6.364-6.364 1.414 1.414z" />
                          </svg>
                          Retour
                        </Button>
                        <Button
                          onClick={() => {
                            setShowManageCompany(true);
                          }}
                          size="sm"
                          variant="primary"
                          className="me-2 admin-primary"
                          disabled={isLoading}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="18"
                            height="18"
                            style={{ marginBottom: '2px', marginRight: '2px' }}
                          >
                            <path
                              d="M15.7279 9.57629L14.3137 8.16207L5 17.4758V18.89H6.41421L15.7279 9.57629ZM17.1421 8.16207L18.5563 6.74786L17.1421 5.33365L15.7279 6.74786L17.1421 8.16207ZM7.24264 20.89H3V16.6474L16.435 3.21233C16.8256 2.8218 17.4587 2.8218 17.8492 3.21233L20.6777 6.04075C21.0682 6.43128 21.0682 7.06444 20.6777 7.45497L7.24264 20.89Z"
                              fill="rgba(255,255,255,1)"
                            ></path>
                          </svg>
                          Modifier le nom
                        </Button>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
                {errorForm && (
                  <div className="mt-3">
                    <FormAlert variant="danger" data={errorForm} />
                  </div>
                )}
                {successForm && !errorForm && (
                  <div className="mt-3">
                    <FormAlert
                      className="mt-3"
                      variant="success"
                      data={successForm}
                    />
                  </div>
                )}
              </Col>
              <Col xs={12}>
                <Card className="mb-3 pb-3">
                  <Card.Body className="bg-light">
                    <Row className="gx-2 gy-3">
                      <Col md={12}>
                        <h5 className="admin-title companyRightManagePeople">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="18"
                            height="18"
                            style={{
                              marginRight: '4px',
                              marginBottom: '2px'
                            }}
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path
                              d="M8 4h13v2H8V4zM5 3v3h1v1H3V6h1V4H3V3h2zM3 14v-2.5h2V11H3v-1h3v2.5H4v.5h2v1H3zm2 5.5H3v-1h2V18H3v-1h3v4H3v-1h2v-.5zM8 11h13v2H8v-2zm0 7h13v2H8v-2z"
                              fill="rgba(75,74,77,1)"
                            />
                          </svg>
                          Formules :
                        </h5>
                      </Col>
                      {object.formulas && (
                        <Col xs={12}>
                          <Table
                            bordered
                            responsive
                            className="admin-table mt-2"
                          >
                            <thead>
                              <tr>
                                <th scope="col">Nom</th>
                                <th scope="col">Conditions</th>
                                <th scope="col">Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {object.formulas.map((formula, index) => (
                                <tr key={index} id={`step-${formula.id}`}>
                                  <td>{formula.name}</td>
                                  <td>
                                    {formula.conditions &&
                                    formula.conditions.length > 0 ? (
                                      <Fragment>
                                        {formula.conditions[
                                          formula.conditions.length - 1
                                        ] &&
                                        formula.conditions[
                                          formula.conditions.length - 1
                                        ].value > 0 &&
                                        formula.conditions[
                                          formula.conditions.length - 1
                                        ].operator &&
                                        formula.conditions[
                                          formula.conditions.length - 1
                                        ].operator.label !== '=' ? (
                                          <SoftBadge
                                            bg="danger"
                                            className="me-2"
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 24 24"
                                              width="14"
                                              height="14"
                                              style={{
                                                marginBottom: '2px',
                                                marginRight: '4px'
                                              }}
                                            >
                                              <path
                                                fill="none"
                                                d="M0 0h24v24H0z"
                                              />
                                              <path
                                                d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-1-7v2h2v-2h-2zm0-8v6h2V7h-2z"
                                                fill="rgba(147,35,56,1)"
                                              />
                                            </svg>
                                            {formula.conditions.length}{' '}
                                            condition
                                            {formula.conditions.length > 1 &&
                                              's'}
                                          </SoftBadge>
                                        ) : (
                                          <SoftBadge
                                            bg="success"
                                            className="me-2"
                                          >
                                            {formula.conditions.length}{' '}
                                            condition
                                            {formula.conditions.length > 1 &&
                                              's'}
                                          </SoftBadge>
                                        )}
                                      </Fragment>
                                    ) : formula.fixed_value ? (
                                      <SoftBadge bg="success" className="me-2">
                                        Participation fixe :{' '}
                                        {formula.fixed_value}€
                                      </SoftBadge>
                                    ) : (
                                      <SoftBadge bg="danger" className="me-2">
                                        Aucune condition
                                      </SoftBadge>
                                    )}
                                  </td>
                                  <td className="">
                                    <Button
                                      size="sm"
                                      variant="primary"
                                      className="me-2 admin-primary"
                                      type="button"
                                      onClick={() => {
                                        setSelectedObject(formula);
                                        setFormData({
                                          name: formula.name,
                                          fixed_value: formula.fixed_value,
                                          company_id: id
                                        });
                                        setShowManage(true);
                                      }}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        width="14"
                                        height="14"
                                        style={{
                                          marginBottom: '2px'
                                        }}
                                      >
                                        <path
                                          d="M16.757 3l-2 2H5v14h14V9.243l2-2V20a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12.757zm3.728-.9L21.9 3.516l-9.192 9.192-1.412.003-.002-1.417L20.485 2.1z"
                                          fill="rgba(255,255,255,1)"
                                        />
                                      </svg>
                                    </Button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </Col>
                      )}
                      <Col
                        xs={12}
                        className={classNames('', {
                          'mt-3': object.formulas.length > 0
                        })}
                      >
                        <Button
                          size="sm"
                          variant="falcon-default"
                          className="me-2"
                          onClick={() => {
                            setShowManage(true);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="20"
                            height="20"
                            style={{
                              marginBottom: '2px',
                              marginRight: '3px'
                            }}
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z" />
                          </svg>
                          Ajouter une{' '}
                          {object.formulas.length > 0 ? 'autre' : ''} formule
                        </Button>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12}>
                <Card>
                  <Card.Body>
                    <Row className="flex-between-center">
                      <Col xs="auto">
                        <Button
                          size="sm"
                          variant="falcon-default"
                          className="me-2"
                          onClick={() => {
                            window.location.href =
                              '/administration/entreprises';
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="20"
                            height="20"
                            style={{ marginBottom: '2px' }}
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M10.828 12l4.95 4.95-1.414 1.414L8 12l6.364-6.364 1.414 1.414z" />
                          </svg>
                          Retour
                        </Button>
                        <Button
                          size="sm"
                          variant="primary"
                          className="me-2 admin-primary"
                          type="submit"
                          disabled={isLoading}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="18"
                            height="18"
                            style={{ marginBottom: '2px', marginRight: '4px' }}
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path
                              d="M7 19v-6h10v6h2V7.828L16.172 5H5v14h2zM4 3h13l4 4v13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm5 12v4h6v-4H9z"
                              fill="rgba(255,255,255,1)"
                            />
                          </svg>
                          Enregistrer test
                        </Button>
                      </Col>
                      <Col className="text-end">
                        <Button
                          onClick={() => {
                            setShowDeleteCompany(true);
                          }}
                          size="sm"
                          variant="falcon-default"
                          className=" admin-primary"
                          disabled={isLoading}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="18"
                            height="18"
                          >
                            <path
                              d="M17 6H22V8H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V8H2V6H7V3C7 2.44772 7.44772 2 8 2H16C16.5523 2 17 2.44772 17 3V6ZM18 8H6V20H18V8ZM9 11H11V17H9V11ZM13 11H15V17H13V11ZM9 4V6H15V4H9Z"
                              fill="rgba(255,0,0,1)"
                            ></path>
                          </svg>
                        </Button>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Form>
          <Form>
            <Offcanvas
              show={showManage}
              onHide={() => {
                setShowManage(false);
                setSelectedObject({});
                setFormData({
                  name: '',
                  fixed_value: '',
                  company_id: id
                });
              }}
              placement={'end'}
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>
                  {selectedObject.id !== null && selectedObject.id !== undefined
                    ? 'Modifier'
                    : 'Ajouter'}{' '}
                  une formule
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Row className="gx-2 gy-3 mb-3">
                  <Col xs={12}>
                    {errorForm && (
                      <div className="mt-0">
                        <FormAlert variant="danger" data={errorForm} />
                      </div>
                    )}
                  </Col>
                  <Col md={12}>
                    {selectedObject &&
                    selectedObject.conditions &&
                    selectedObject.conditions.length &&
                    selectedObject.conditions[
                      selectedObject.conditions.length - 1
                    ] &&
                    selectedObject.conditions[
                      selectedObject.conditions.length - 1
                    ].value > 0 &&
                    selectedObject.conditions[
                      selectedObject.conditions.length - 1
                    ].operator &&
                    selectedObject.conditions[
                      selectedObject.conditions.length - 1
                    ].operator.label !== '=' ? (
                      <IconAlert variant="danger">
                        <p className="mb-0 smaller-p">
                          Attention ! Aucune règle applicable pour les indices
                          inférieurs à{' '}
                          {
                            selectedObject.conditions[
                              selectedObject.conditions.length - 1
                            ].value
                          }
                          . Il est fortement recommandé d'ajouter cette règle.
                          Le cas échéant, tout salarié avec un indice inférieur
                          à{' '}
                          {
                            selectedObject.conditions[
                              selectedObject.conditions.length - 1
                            ].value
                          }{' '}
                          ne pourra pas générer de ticket pour cette formule.
                        </p>
                        <Button
                          variant="falcon-danger"
                          className="me-2 mb-1 mt-2"
                          size="sm"
                          onClick={() => setShowManageConditions(true)}
                        >
                          Gérer les conditions
                        </Button>
                      </IconAlert>
                    ) : (
                      <IconAlert variant="info">
                        {selectedObject.id ? (
                          <>
                            <p className="mb-0 smaller-p">
                              {(selectedObject.conditions &&
                                selectedObject.conditions.length > 0) ||
                              selectedObject.fixed_value
                                ? 'Les conditions de cette formule sont correctement configurées.'
                                : 'Vous pouvez ajouter des conditions à cette formule en cliquant sur le bouton ci-dessous :'}
                            </p>
                            <Button
                              variant="falcon-info"
                              className="me-2 mb-1 mt-2"
                              size="sm"
                              onClick={() => setShowManageConditions(true)}
                            >
                              Gérer les conditions
                            </Button>
                          </>
                        ) : (
                          <p className="mb-0 smaller-p">
                            Enregistrez cette nouvelle formule pour pouvoir y
                            ajouter des conditions.
                          </p>
                        )}
                      </IconAlert>
                    )}
                  </Col>
                  <Col md="12">
                    <Form.Group controlId="name">
                      <Form.Label>Nom :</Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        placeholder="Nom de la formule"
                        value={formData.name || ''}
                        onChange={handleFieldChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md="12">
                    <Form.Group controlId="fixed_value">
                      <Form.Label
                        className={classNames('', {
                          'text-danger':
                            formData.fixed_value &&
                            selectedObject.conditions &&
                            selectedObject.conditions.length > 0
                        })}
                      >
                        Participation fixe (si concerné) :
                      </Form.Label>
                      <InputGroup className="mb-3">
                        <Form.Control
                          type="number"
                          name="fixed_value"
                          placeholder="4.32"
                          value={formData.fixed_value || ''}
                          onChange={handleFieldChange}
                          aria-describedby="basic-addon2"
                        />
                        <InputGroup.Text
                          id="basic-addon2"
                          className="text-dark"
                        >
                          €
                        </InputGroup.Text>
                      </InputGroup>
                    </Form.Group>
                    {formData.fixed_value &&
                      selectedObject.conditions &&
                      selectedObject.conditions.length > 0 && (
                        <IconAlert variant="danger">
                          <p className="mb-0 smaller-p">
                            Attention,{' '}
                            <b>
                              la participation fixe n'est pas prise en compte
                              car des conditions sont configurées
                            </b>
                            . Retirez la participation fixe et enregistrez le
                            formulaire ou supprimez les conditions en cliquant
                            sur le bouton pour prendre en compte la
                            participation fixe.
                          </p>
                          <Button
                            variant="falcon-danger"
                            className="me-2 mb-1 mt-2 d-block"
                            size="sm"
                            onClick={() => {
                              setFormData({
                                ...formData,
                                fixed_value: ''
                              });
                            }}
                          >
                            Retirer la participation fixe
                          </Button>
                          <Button
                            variant="falcon-danger"
                            className="me-2 mb-1 mt-2 d-block"
                            size="sm"
                            onClick={() => setShowManageConditions(true)}
                          >
                            Gérer les conditions
                          </Button>
                        </IconAlert>
                      )}
                  </Col>
                </Row>
                <Button
                  size="sm"
                  variant="falcon-default"
                  className="me-2"
                  onClick={() => {
                    setShowManage(false);
                    setSelectedObject({});
                    setFormData({
                      name: '',
                      fixed_value: '',
                      company_id: id
                    });
                  }}
                >
                  Fermer
                </Button>
                <Button
                  size="sm"
                  variant="primary"
                  type="submit"
                  onClick={e => {
                    handleSubmit(e);
                  }}
                  disabled={isLoading}
                  className="me-2 admin-primary"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="18"
                    height="18"
                    style={{
                      marginBottom: '2px',
                      marginRight: '4px'
                    }}
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path
                      d="M7 19v-6h10v6h2V7.828L16.172 5H5v14h2zM4 3h13l4 4v13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm5 12v4h6v-4H9z"
                      fill="rgba(255,255,255,1)"
                    />
                  </svg>
                  Enregistrer
                </Button>
                {selectedObject.id !== null && selectedObject.id !== undefined && (
                  <Button
                    size="sm"
                    variant="danger"
                    className="me-0 float-end"
                    type="button"
                    onClick={() => {
                      setShowDelete(true);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="14"
                      height="14"
                      style={{
                        marginBottom: '2px'
                      }}
                    >
                      <path
                        d="M7 4V2h10v2h5v2h-2v15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V6H2V4h5zM6 6v14h12V6H6zm3 3h2v8H9V9zm4 0h2v8h-2V9z"
                        fill="rgba(255,255,255,1)"
                      />
                    </svg>
                  </Button>
                )}
              </Offcanvas.Body>
            </Offcanvas>
          </Form>
          <Form>
            <Offcanvas
              show={showManageConditions}
              onHide={() => {
                setShowManageConditions(false);
              }}
              placement={'end'}
              style={{ width: '800px' }}
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>
                  Gérer les conditions de la formule "{selectedObject.name}"
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Row className="gx-2 gy-3 mb-3">
                  {selectedObject.conditions &&
                    selectedObject.conditions.length > 0 && (
                      <>
                        <Table bordered responsive className="admin-table mt-2">
                          <thead>
                            <tr>
                              <th scope="col">Si l'indice du salarié est</th>
                              <th scope="col">À</th>
                              <th scope="col">
                                Alors, le montant de la participation est égal à
                              </th>
                              <th scope="col"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {selectedObject.conditions.map(
                              (condition, index) => (
                                <tr key={index}>
                                  <td>
                                    {condition.operator &&
                                      condition.operator.label}
                                  </td>
                                  <td>{condition.value}</td>
                                  <td>{condition.result}€</td>
                                  <td className="text-center">
                                    <Button
                                      size="sm"
                                      variant="primary"
                                      className="me-2 admin-primary"
                                      type="button"
                                      onClick={() => {
                                        setShowManageCondition(true);
                                        setSelectedCondition(condition);
                                        setFormDataCondition({
                                          formula_id: selectedObject.id,
                                          operator_id: condition.operator
                                            ? condition.operator.id
                                            : '',
                                          value: condition.value,
                                          result: condition.result
                                        });
                                      }}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        width="14"
                                        height="14"
                                        style={{
                                          marginBottom: '2px'
                                        }}
                                      >
                                        <path
                                          d="M16.757 3l-2 2H5v14h14V9.243l2-2V20a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12.757zm3.728-.9L21.9 3.516l-9.192 9.192-1.412.003-.002-1.417L20.485 2.1z"
                                          fill="rgba(255,255,255,1)"
                                        />
                                      </svg>
                                    </Button>
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </Table>
                        {selectedObject.conditions[
                          selectedObject.conditions.length - 1
                        ] &&
                          selectedObject.conditions[
                            selectedObject.conditions.length - 1
                          ].value > 0 &&
                          selectedObject.conditions[
                            selectedObject.conditions.length - 1
                          ].operator &&
                          selectedObject.conditions[
                            selectedObject.conditions.length - 1
                          ].operator.label !== '=' && (
                            <IconAlert variant="danger">
                              <p className="mb-0 smaller-p">
                                Attention ! Aucune règle applicable pour les
                                indices inférieurs à{' '}
                                {
                                  selectedObject.conditions[
                                    selectedObject.conditions.length - 1
                                  ].value
                                }
                                . Il est fortement recommandé d'ajouter cette
                                règle. Le cas échéant, tout salarié avec un
                                indice inférieur à{' '}
                                {
                                  selectedObject.conditions[
                                    selectedObject.conditions.length - 1
                                  ].value
                                }{' '}
                                ne pourra pas générer de ticket pour cette
                                formule.
                              </p>
                            </IconAlert>
                          )}
                      </>
                    )}
                  {operators.length > 0 && (
                    <Col xs={12} className="mt-0">
                      <Button
                        size="sm"
                        variant="primary"
                        className="me-2 admin-primary"
                        onClick={() => {
                          setSelectedCondition({});
                          setShowManageCondition(true);
                          setFormDataCondition({
                            formula_id: selectedObject.id,
                            operator_id: operators[0].id,
                            value: '',
                            result: ''
                          });
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="20"
                          height="20"
                          style={{
                            marginBottom: '2px',
                            marginRight: '3px'
                          }}
                        >
                          <path fill="none" d="M0 0h24v24H0z" />
                          <path
                            d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z"
                            fill="rgba(255,255,255,1)"
                          />
                        </svg>
                        Ajouter une{' '}
                        {selectedObject.conditions &&
                        selectedObject.conditions.length > 0
                          ? 'autre'
                          : ''}{' '}
                        condition
                      </Button>
                      <hr />
                    </Col>
                  )}
                </Row>

                <Modal
                  show={showManageCondition}
                  onHide={() => {
                    setShowManageCondition(false);
                    setSelectedCondition({});
                  }}
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <Modal.Header closeButton>
                    <h5 className="admin-title">
                      {selectedCondition.id ? 'Modifier la ' : 'Ajouter une '}
                      condition
                    </h5>
                  </Modal.Header>
                  <Modal.Body>
                    <form onSubmit={handleConditionSubmit}>
                      <Col xs={12}>
                        {errorFormCondition && (
                          <div className="mt-0">
                            <FormAlert
                              variant="danger"
                              data={errorFormCondition}
                            />
                          </div>
                        )}
                      </Col>
                      {operators.length > 0 && (
                        <Col md="12" className="mb-3">
                          <Form.Group controlId="operator_id">
                            <Form.Label>
                              Si l'indice du salarié est :
                            </Form.Label>
                            <Form.Select
                              aria-label="Si indice est"
                              name="operator_id"
                              value={formDataCondition.operator_id}
                              onChange={handleFieldConditionChange}
                            >
                              {operators.map((operator, index) => (
                                <option value={operator.id} key={index}>
                                  {operator.label}
                                </option>
                              ))}
                            </Form.Select>
                          </Form.Group>
                        </Col>
                      )}
                      <Col xs={12} className="mb-3">
                        <Form.Group controlId="value">
                          <Form.Label>À :</Form.Label>
                          <Form.Control
                            type="number"
                            name="value"
                            placeholder="831"
                            value={formDataCondition.value}
                            onChange={handleFieldConditionChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={7} className="mb-5">
                        <Form.Group controlId="value">
                          <Form.Label>
                            Alors, le montant de la participation est égal à :
                          </Form.Label>
                          <InputGroup className="mb-3">
                            <Form.Control
                              type="number"
                              name="result"
                              placeholder="7.7"
                              value={formDataCondition.result || ''}
                              onChange={handleFieldConditionChange}
                            />
                            <InputGroup.Text
                              id="basic-addon2"
                              className="text-dark"
                            >
                              €
                            </InputGroup.Text>
                          </InputGroup>
                        </Form.Group>
                      </Col>
                      <div className="d-block mt-3">
                        {selectedCondition.id && (
                          <div className="float-start">
                            <OverlayTrigger
                              overlay={
                                <Tooltip id="overlay-trigger-example">
                                  Archiver la condition (définitif)
                                </Tooltip>
                              }
                            >
                              <Button
                                size="sm"
                                variant="danger"
                                className="me-0 float-start"
                                type="button"
                                onClick={() => {
                                  deleteCondition(selectedCondition.id);
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  width="14"
                                  height="14"
                                  style={{
                                    marginBottom: '2px'
                                  }}
                                >
                                  <path
                                    d="M7 4V2h10v2h5v2h-2v15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V6H2V4h5zM6 6v14h12V6H6zm3 3h2v8H9V9zm4 0h2v8h-2V9z"
                                    fill="rgba(255,255,255,1)"
                                  />
                                </svg>
                              </Button>
                            </OverlayTrigger>
                          </div>
                        )}
                        <div className="float-end">
                          <Button
                            size="sm"
                            variant="falcon-default"
                            className="me-2"
                            onClick={() => {
                              setShowManageCondition(false);
                              setSelectedCondition({});
                            }}
                          >
                            Annuler
                          </Button>
                          <Button
                            onClick={() => {
                              handleSubmit();
                            }}
                            size="sm"
                            variant="primary"
                            className="me-2 admin-primary"
                            type="submit"
                            disabled={isLoading}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="18"
                              height="18"
                              style={{
                                marginBottom: '2px',
                                marginRight: '4px'
                              }}
                            >
                              <path fill="none" d="M0 0h24v24H0z" />
                              <path
                                d="M7 19v-6h10v6h2V7.828L16.172 5H5v14h2zM4 3h13l4 4v13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm5 12v4h6v-4H9z"
                                fill="rgba(255,255,255,1)"
                              />
                            </svg>
                            Enregistrer
                          </Button>
                        </div>
                      </div>
                    </form>
                  </Modal.Body>
                </Modal>
                <Button
                  size="sm"
                  variant="falcon-default"
                  className="me-2"
                  onClick={() => {
                    setShowManageConditions(false);
                  }}
                >
                  Fermer
                </Button>
              </Offcanvas.Body>
            </Offcanvas>
          </Form>
          <Offcanvas
            show={showDelete}
            onHide={() => {
              setShowDelete(false);
            }}
            placement={'end'}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>
                {selectedObject.id !== undefined && (
                  <>
                    L'archivage de la formule "{`${selectedObject.name}`}" est
                    définitif.
                  </>
                )}
                <br />
                Voulez-vous continuer ?
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Button
                size="sm"
                variant="falcon-danger"
                className="me-2"
                onClick={() => {
                  deleteFormula();
                }}
              >
                Oui, archiver
              </Button>
              <Button
                size="sm"
                variant="falcon-default"
                className="me-2"
                onClick={() => {
                  setShowDelete(false);
                }}
              >
                Annuler
              </Button>
            </Offcanvas.Body>
          </Offcanvas>
        </>
      )}
    </>
  );
};

export default ManageCompanies;
