export const adminRoutes = {
  label: 'Administration',
  children: [
    {
      name: 'Sociétés',
      icon: 'shapes',
      to: '/administration/entreprises',
      active: true
    },
    {
      name: 'Salariés',
      icon: 'users',
      to: '/administration/salaries',
      active: true
    },
    {
      name: 'Caissiers',
      icon: 'users',
      to: '/administration/caissiers',
      active: true
    },
    {
      name: 'Comptables',
      icon: 'users',
      to: '/administration/comptables',
      active: true
    },
    {
      name: 'Administrateurs',
      icon: 'users',
      to: '/administration/admins',
      active: true
    }
  ]
};

export const accountantRoutes = {
  label: 'Comptabilité',
  children: [
    {
      name: 'Tableau de bord',
      icon: 'chart-pie',
      to: '/comptabilite/tableau-de-bord',
      active: true
    }
  ]
};

export default [adminRoutes, accountantRoutes];
