import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import avatar from 'assets/img/avatar.png';
import { Button, Row, Col, Card, Modal, Spinner } from 'react-bootstrap';
import { PutUser, ShowUser } from '../../../../services/UserService';
import Avatar from '../../../common/Avatar';
import FileUploader from '../../commons/fileUploader';
import HeaderEmployee from '../header';
import wave from '../../../../assets/img/wave.png';
import IconAlert from '../../../common/IconAlert';

const EmployeeProfilPicture = () => {
  const [trigger] = useState(Date.now());
  const [user, setUser] = useState([]);
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    profile_picture_url: ''
  });

  useEffect(() => {
    const fetchUser = async () => {
      const responseUser = await ShowUser(
        JSON.parse(localStorage.getItem('user')).id
      );
      if (responseUser.success === true) {
        if (!responseUser.data.profile_picture_url) {
          setUser(responseUser.data);
          setFormData({
            profile_picture_url: responseUser.data.profile_picture_url
          });
        } else {
          window.location.href = '/salaries/accueil';
        }
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    fetchUser();
  }, [trigger]);

  const handleSubmit = async e => {
    e.preventDefault();
    setIsLoading(true);
    setShow(false);
    const response = await PutUser(
      'profile.profile_picture',
      user.id,
      formData
    );
    if (response.success === true) {
      localStorage.setItem('user', JSON.stringify(response.data));
      setTimeout(function () {
        window.location.href = '/salaries/accueil';
      }, 1500);
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
  };

  return (
    <Row>
      <Col xs={12} className="px-0">
        <HeaderEmployee title="Initialiser mon compte" hideProfil={true} />
        <img src={wave} alt="picture-wave" className="wavePicture" />
      </Col>
      {isLoading === true ? (
        <Col xs={12} className="mt-3">
          <Spinner animation="border" role="status" size="xs">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </Col>
      ) : (
        <Col xs={12}>
          <Row className="justify-content-center">
            <Col xs={12}>
              <Card className="customCard pt-3 text">
                <Row className="justify-content-center pb-4">
                  {!formData.profile_picture_url && (
                    <Col xs={11} className="mt-2">
                      <Avatar
                        src={
                          formData.profile_picture_url
                            ? formData.profile_picture_url
                            : avatar
                        }
                        className="avatar-profile-pic"
                        size="4xl"
                      />
                    </Col>
                  )}
                  <Col xs={12}>
                    <Card.Title className="mt-3 customTitleProfil">
                      Ajouter ma photo
                    </Card.Title>
                  </Col>
                  <Col xs={12} className="px-4 mt-3 text-start">
                    <IconAlert variant="info">
                      <p className="mb-0" style={{ fontSize: '12px' }}>
                        Votre photo est obligatoire et permet de vous identifier
                        lors du passage en caisse.
                      </p>
                    </IconAlert>
                  </Col>
                  <Col xs={12} className="mt-0 px-4">
                    <FileUploader
                      pathToUpload="users/profilepics"
                      formData={formData}
                      setFormData={setFormData}
                      format="img"
                      databaseField="profile_picture_url"
                      cropperEnabled={true}
                      currentImage={user.profile_picture_url}
                      reloadAfterReplace={true}
                    />
                  </Col>
                  <Col xs={12} className="px-4 mt-3 text-start">
                    <p
                      className="mb-0 text-center"
                      style={{ fontSize: '12px' }}
                    >
                      <b>
                        Votre photo doit être prise de face, visage dégagé en
                        regardant l'objectif.
                      </b>
                    </p>
                  </Col>
                  {formData.profile_picture_url && (
                    <Col xs={12} className="px-4 mb-5">
                      <Button
                        type="submit"
                        color="primary"
                        className="w-100 mt-3 px-4"
                        onClick={() => {
                          window.scrollTo({ top: 0, behavior: 'smooth' });
                          setShow(true);
                        }}
                      >
                        Enregistrer ma photo
                      </Button>
                    </Col>
                  )}
                  <Modal
                    show={show}
                    onHide={() => {
                      setShow(false);
                    }}
                    backdrop="static"
                    keyboard={false}
                  >
                    <Modal.Header>
                      <Modal.Title>Valider la photo ?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      L'enregistrement de votre photo est définitif. Pour
                      modifier votre photo plus tard, vous devrez contacter un
                      administrateur du Ciarus.
                      <br />
                      <b>Continuer avec cette photo ?</b>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        onClick={() => {
                          window.location.reload();
                        }}
                      >
                        Reprendre
                      </Button>
                      <Button variant="primary" onClick={handleSubmit}>
                        Oui, continuer
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </Row>
              </Card>
            </Col>
          </Row>
        </Col>
      )}
    </Row>
  );
};

EmployeeProfilPicture.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

EmployeeProfilPicture.defaultProps = {
  layout: 'simple',
  hasLabel: false
};

export default EmployeeProfilPicture;
