import React, { Fragment, useEffect, useState } from 'react';
import * as Moment from 'moment';
import { Button, Card, Col, Row, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { usePagination, useSortBy, useTable } from 'react-table';
import PropTypes from 'prop-types';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import Select from 'react-select';
import FalconLightBox from '../../../../common/FalconLightBox';
import Avatar from '../../../../common/Avatar';
import SoftBadge from '../../../../common/SoftBadge';
import { IndexCompany } from '../../../../../services/CompanyService';
import ExportEmployees from '../../../../../exports/employees';

const TableEmployees = ({ initialApiObjects }) => {
  const [trigger, setTrigger] = useState(Date.now());
  const [initialObjects, setInitialObjects] = useState([]);
  const [objects, setObjects] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [formData, setFormData] = useStateWithCallbackLazy({
    companies: [],
    search: ''
  });

  const getFilename = fullPath => {
    return fullPath.replace(/^.*[\\/]/, '');
  };

  const transformObjects = objects => {
    let transformedApiObjects = [];
    objects.map(obj => {
      transformedApiObjects.push({
        profile_pic:
          obj.profile_picture_url &&
          getFilename(obj.profile_picture_url) !== 'default_port.png' ? (
            <FalconLightBox image={obj.profile_picture_url}>
              <Avatar src={obj.profile_picture_url} />
            </FalconLightBox>
          ) : (
            <Avatar name={`${obj.firstname} ${obj.lastname}`} />
          ),
        firstname: obj.firstname,
        lastname: obj.lastname,
        active_toString: obj.active === 1 ? 'Oui' : 'Non',
        active:
          obj.active === 1 ? (
            <SoftBadge bg="success">Oui</SoftBadge>
          ) : (
            <SoftBadge bg="warning">Non</SoftBadge>
          ),
        company_id: obj.company_id,
        company_toString: obj.company && obj.company.name,
        company: obj.company && obj.company.name && (
          <SoftBadge bg="success">{obj.company?.name}</SoftBadge>
        ),
        registration_number: obj.registration_number && (
          <SoftBadge bg="success">{obj.registration_number}</SoftBadge>
        ),
        registration_number_toString: obj.registration_number,
        salary_coefficient:
          !obj.salary_coefficient &&
          obj.company?.formulas &&
          obj.company?.formulas.length > 0 &&
          obj.company?.formulas.filter(f => f.conditions && f.conditions.length)
            .length > 0 ? (
            <SoftBadge bg="warning">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="20"
                height="20"
                style={{
                  marginBottom: '2px',
                  marginRight: '2px'
                }}
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-1-7v2h2v-2h-2zm0-8v6h2V7h-2z"
                  fill="rgba(147,35,56,1)"
                />
              </svg>
              À configurer
            </SoftBadge>
          ) : obj.salary_coefficient ? (
            <SoftBadge bg="success">{obj.salary_coefficient}</SoftBadge>
          ) : (
            ''
          ),
        salary_coefficient_toString: obj.salary_coefficient,
        emailOriginal: obj.email,
        email: (
          <SoftBadge bg="success" className="me-2">
            <a href={`mailto:${obj.email}`}>{obj.email}</a>
          </SoftBadge>
        ),
        phone: obj.phone,
        created_at: Moment(obj.created_at).local().format('DD/MM/YYYY'),
        last_login_at:
          obj.last_login_at &&
          Moment(obj.last_login_at).local().format('DD/MM/YYYY'),
        action: (
          <>
            <Button
              size="sm"
              variant="primary"
              className="me-2 admin-primary"
              type="button"
              onClick={() => {
                window.location.href = `/administration/salaries/${obj.id}/modifier`;
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="14"
                height="14"
                style={{
                  marginBottom: '2px'
                }}
              >
                <path
                  d="M16.757 3l-2 2H5v14h14V9.243l2-2V20a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12.757zm3.728-.9L21.9 3.516l-9.192 9.192-1.412.003-.002-1.417L20.485 2.1z"
                  fill="rgba(255,255,255,1)"
                />
              </svg>
            </Button>
          </>
        )
      });
    });
    setInitialObjects(transformedApiObjects);
    setObjects(transformedApiObjects);
  };

  useEffect(() => {
    const fetchObjects = async () => {
      transformObjects(initialApiObjects.filter(data => !data.deleted_at));
    };
    const fetchCompanies = async () => {
      const responseObjects = await IndexCompany();
      if (responseObjects.success === true) {
        let tmpCompanies = [];
        responseObjects.data.map(r => {
          tmpCompanies.push({
            value: r.id,
            label: r.name
          });
        });
        setCompanies(tmpCompanies);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    fetchCompanies();
    fetchObjects();
  }, [trigger]);

  const updateSearch = data => {
    if (data.companies.length === 0 && data.search.length === 0) {
      setObjects(initialObjects);
    } else {
      let tmpFilterObjects = initialObjects;
      if (data.search.length > 0) {
        let tmpSearch = data.search.toLowerCase();
        tmpFilterObjects = tmpFilterObjects.filter(obj => {
          let result = false;
          if (
            (obj.lastname && obj.lastname.toLowerCase().includes(tmpSearch)) ||
            (obj.registration_number_toString &&
              obj.registration_number_toString
                .toLowerCase()
                .includes(tmpSearch)) ||
            (obj.salary_coefficient &&
              obj.salary_coefficient
                .toString()
                .toLowerCase()
                .includes(tmpSearch)) ||
            (obj.firstname &&
              obj.firstname.toLowerCase().includes(tmpSearch)) ||
            (obj.occupation &&
              obj.occupation.toLowerCase().includes(tmpSearch)) ||
            (obj.emailOriginal &&
              obj.emailOriginal.toLowerCase().includes(tmpSearch))
          ) {
            result = true;
          }
          return result;
        });
      }
      if (data.companies.length > 0) {
        tmpFilterObjects = tmpFilterObjects.filter(obj => {
          let result = false;
          data.companies.map(company => {
            if (parseInt(obj.company_id) === company.value) {
              result = true;
            }
          });
          return result;
        });
      }
      setObjects(tmpFilterObjects);
    }
  };

  return (
    <>
      {initialApiObjects.length > 0 && (
        <Card className="mt-3">
          <Card.Body className="">
            <Row className="align-items-end">
              <Col xs={4} className="mb-3">
                <Form.Label>Recherche</Form.Label>
                <Form.Control
                  type="text"
                  name="search"
                  placeholder="Nom, prénom, email..."
                  value={formData.search || ''}
                  onChange={event => {
                    setFormData(
                      {
                        ...formData,
                        search: event.target.value
                      },
                      data => {
                        updateSearch(data);
                      }
                    );
                  }}
                />
              </Col>
              <Col xs={4} className="mb-3">
                <Form.Label>Société(s)</Form.Label>
                <Select
                  closeMenuOnSelect={false}
                  options={companies}
                  placeholder="Choisir..."
                  isMulti
                  name="companies"
                  classNamePrefix="react-select"
                  value={formData.companies}
                  onChange={value => {
                    setFormData(
                      {
                        ...formData,
                        companies: value
                      },
                      data => {
                        updateSearch(data);
                      }
                    );
                  }}
                />
              </Col>
              {objects && objects.length > 0 && (
                <Fragment>
                  <Col xs={12} className="mt-3">
                    <Table data={objects} setTrigger={setTrigger} />
                  </Col>
                </Fragment>
              )}
            </Row>
          </Card.Body>
        </Card>
      )}
    </>
  );
};

function Table({ data }) {
  const columns = React.useMemo(
    () => [
      {
        accessor: 'profile_pic',
        Header: ''
      },
      {
        accessor: 'firstname',
        Header: 'Prénom'
      },
      {
        accessor: 'lastname',
        Header: 'Nom'
      },
      {
        accessor: 'active',
        Header: 'Actif'
      },
      {
        accessor: 'company',
        Header: 'Société'
      },
      {
        accessor: 'registration_number',
        Header: 'Matricule'
      },
      {
        accessor: 'salary_coefficient',
        Header: 'Indice'
      },
      {
        accessor: 'email',
        Header: 'Email'
      },
      {
        accessor: 'action',
        Header: ''
      }
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 15 }
    },
    useSortBy,
    usePagination
  );

  return (
    <>
      <div className="table-responsive scrollbar-visible">
        <table
          {...getTableProps()}
          className="table table-striped table-bordered admin-table w-100 d-block d-table table-membres"
        >
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr key={index} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, indexCol) => (
                  <th
                    key={indexCol}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render('Header')}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="18"
                            height="18"
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="18"
                            height="18"
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M12 10.828l-4.95 4.95-1.414-1.414L12 8l6.364 6.364-1.414 1.414z" />
                          </svg>
                        )
                      ) : (
                        ''
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr key={i} {...row.getRowProps()}>
                  {row.cells.map((cell, indexCell) => {
                    return (
                      <td key={indexCell} {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="pagination d-block mt-3">
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-2"
          onClick={() => {
            gotoPage(0);
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          disabled={!canPreviousPage}
        >
          {'<<'}
        </Button>
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-2"
          onClick={() => {
            previousPage();
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          disabled={!canPreviousPage}
        >
          {'<'}
        </Button>
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-2"
          onClick={() => {
            nextPage();
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          disabled={!canNextPage}
        >
          {'>'}
        </Button>
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-3"
          onClick={() => {
            gotoPage(pageCount - 1);
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          disabled={!canNextPage}
        >
          {'>>'}
        </Button>{' '}
        <span className="bottom-table">
          Page{' '}
          <strong>
            {pageIndex + 1} sur {pageOptions.length}
          </strong>{' '}
        </span>
        <Form.Select
          className="d-inline-block w-auto ms-3 table-select"
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value));
          }}
          aria-label="Default select example"
        >
          {[15, 30, 50, 100, 150].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Afficher {pageSize} éléments
            </option>
          ))}
        </Form.Select>
        <span className="ps-2 smaller-p">
          Total : {data.length} élément{data.length > 1 ? 's' : ''}
        </span>
        <ExportEmployees users={data} />
      </div>
    </>
  );
}

Table.propTypes = {
  data: PropTypes.array,
  setTrigger: PropTypes.func
};

TableEmployees.propTypes = {
  initialApiObjects: PropTypes.array,
  companyContext: PropTypes.any
};

export default TableEmployees;
