import React, { Fragment, useEffect, useState } from 'react';
import * as Moment from 'moment';
import { Button, Card, Col, Row, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { usePagination, useSortBy, useTable } from 'react-table';
import PropTypes from 'prop-types';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import Select from 'react-select';
import { IndexCompany } from '../../../../services/CompanyService';
import ExportTickets from '../../../../exports/tickets';
import DatePicker from 'react-datepicker';

const TableTickets = ({ initialApiObjects, objects, setObjects }) => {
  const [trigger, setTrigger] = useState(Date.now());
  const [initialObjects, setInitialObjects] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [formData, setFormData] = useStateWithCallbackLazy({
    companies: [],
    search: '',
    start_at: '',
    end_at: ''
  });

  const formatter = new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR'
  });

  const transformObjects = () => {
    let transformedApiObjects = [];
    initialApiObjects.map(obj => {
      transformedApiObjects.push({
        user: obj.user_toString,
        company: obj.company_toString,
        company_id: obj.company_id,
        salary_coefficient: obj.salary_coefficient_toString,
        registration_number: obj.registration_number_toString,
        formula: obj.formula_toString,
        value: formatter.format(obj.calculated_value),
        value_toString: obj.calculated_value,
        validated_at_toString: obj.validated_at,
        validated_at: Moment(obj.validated_at)
          .local()
          .format('DD/MM/YYYY HH:mm')
      });
    });
    setInitialObjects(transformedApiObjects);
    setObjects(transformedApiObjects);
  };

  useEffect(() => {
    const fetchObjects = async () => {
      transformObjects();
    };
    const fetchCompanies = async () => {
      const responseObjects = await IndexCompany();
      if (responseObjects.success === true) {
        let tmpCompanies = [];
        responseObjects.data.map(r => {
          tmpCompanies.push({
            value: r.id,
            label: r.name
          });
        });
        setCompanies(tmpCompanies);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    fetchCompanies();
    fetchObjects();
  }, [trigger]);

  const updateSearch = data => {
    if (
      data.companies.length === 0 &&
      data.search.length === 0 &&
      !data.start_at &&
      !data.end_at
    ) {
      setObjects(initialObjects);
    } else {
      let tmpFilterObjects = initialObjects;
      if (data.search.length > 0) {
        let tmpSearch = data.search.toLowerCase();
        tmpFilterObjects = tmpFilterObjects.filter(obj => {
          let result = false;
          if (
            (obj.user && obj.user.toLowerCase().includes(tmpSearch)) ||
            (obj.registration_number &&
              obj.registration_number.toLowerCase().includes(tmpSearch))
          ) {
            result = true;
          }
          return result;
        });
      }
      if (data.companies.length > 0) {
        tmpFilterObjects = tmpFilterObjects.filter(obj => {
          let result = false;
          data.companies.map(company => {
            if (parseInt(obj.company_id) === company.value) {
              result = true;
            }
          });
          return result;
        });
      }
      if (data.start_at > 0) {
        tmpFilterObjects = tmpFilterObjects.filter(obj => {
          let result = false;
          if (
            Moment(obj.validated_at_toString)
              .local()
              .isSameOrAfter(Moment(data.start_at))
          ) {
            result = true;
          }
          return result;
        });
      }
      if (data.end_at > 0) {
        tmpFilterObjects = tmpFilterObjects.filter(obj => {
          let result = false;
          if (
            Moment(obj.validated_at_toString)
              .local()
              .isSameOrBefore(Moment(data.end_at))
          ) {
            result = true;
          }
          return result;
        });
      }
      setObjects(tmpFilterObjects);
    }
  };

  return (
    <>
      {initialApiObjects.length > 0 && (
        <Card className="mt-3">
          <Card.Body className="">
            <Row className="align-items-end">
              <Col xs={3} className="mb-3">
                <Form.Label>Recherche</Form.Label>
                <Form.Control
                  type="text"
                  name="search"
                  placeholder="Nom, prénom, ..."
                  value={formData.search || ''}
                  onChange={event => {
                    setFormData(
                      {
                        ...formData,
                        search: event.target.value
                      },
                      data => {
                        updateSearch(data);
                      }
                    );
                  }}
                />
              </Col>
              <Col xs={3} className="mb-3">
                <Form.Label>Société(s)</Form.Label>
                <Select
                  closeMenuOnSelect={false}
                  options={companies}
                  placeholder="Choisir..."
                  isMulti
                  name="companies"
                  classNamePrefix="react-select"
                  value={formData.companies}
                  onChange={value => {
                    setFormData(
                      {
                        ...formData,
                        companies: value
                      },
                      data => {
                        updateSearch(data);
                      }
                    );
                  }}
                />
              </Col>
              <Col xs={3} className="mb-3">
                <Form.Label className="fs-0">Date de début</Form.Label>
                <DatePicker
                  selected={formData.start_at}
                  onChange={date => {
                    let tmpFormData = { ...formData, start_at: date };
                    setFormData(tmpFormData);
                    updateSearch(tmpFormData);
                  }}
                  className="form-control"
                  placeholderText="jj/mm/aaaa"
                  dateFormat="dd/MM/yyyy"
                />
              </Col>
              <Col xs={3} className="mb-3">
                <Form.Label className="fs-0">Date de fin</Form.Label>
                <DatePicker
                  selected={formData.end_at}
                  onChange={date => {
                    let tmpFormData = { ...formData, end_at: date };
                    setFormData(tmpFormData);
                    updateSearch(tmpFormData);
                  }}
                  className="form-control"
                  placeholderText="jj/mm/aaaa"
                  dateFormat="dd/MM/yyyy"
                />
              </Col>
              {objects && objects.length > 0 && (
                <Fragment>
                  <Col xs={12} className="mt-3">
                    <Table data={objects} setTrigger={setTrigger} />
                  </Col>
                </Fragment>
              )}
            </Row>
          </Card.Body>
        </Card>
      )}
    </>
  );
};

function Table({ data }) {
  const columns = React.useMemo(
    () => [
      {
        accessor: 'user',
        Header: 'Salarié'
      },
      {
        accessor: 'company',
        Header: 'Société'
      },
      {
        accessor: 'salary_coefficient',
        Header: 'Indice'
      },
      {
        accessor: 'registration_number',
        Header: 'Matricule'
      },
      {
        accessor: 'formula',
        Header: 'Formule'
      },
      {
        accessor: 'value',
        Header: 'Montant participation'
      },
      {
        accessor: 'validated_at',
        Header: 'Passage en caisse',
        sortType: (a, b) => {
          var a1 = new Date(a).getTime();
          var b1 = new Date(b).getTime();
          if (a1 < b1) return 1;
          else if (a1 > b1) return -1;
          else return 0;
        }
      }
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 15 }
    },
    useSortBy,
    usePagination
  );

  return (
    <>
      <div className="table-responsive scrollbar-visible">
        <table
          {...getTableProps()}
          className="table table-striped table-bordered admin-table w-100 d-block d-table table-membres"
        >
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr key={index} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, indexCol) => (
                  <th
                    key={indexCol}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render('Header')}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="18"
                            height="18"
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="18"
                            height="18"
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M12 10.828l-4.95 4.95-1.414-1.414L12 8l6.364 6.364-1.414 1.414z" />
                          </svg>
                        )
                      ) : (
                        ''
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr key={i} {...row.getRowProps()}>
                  {row.cells.map((cell, indexCell) => {
                    return (
                      <td key={indexCell} {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="pagination d-block mt-3">
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-2"
          onClick={() => {
            gotoPage(0);
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          disabled={!canPreviousPage}
        >
          {'<<'}
        </Button>
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-2"
          onClick={() => {
            previousPage();
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          disabled={!canPreviousPage}
        >
          {'<'}
        </Button>
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-2"
          onClick={() => {
            nextPage();
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          disabled={!canNextPage}
        >
          {'>'}
        </Button>
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-3"
          onClick={() => {
            gotoPage(pageCount - 1);
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          disabled={!canNextPage}
        >
          {'>>'}
        </Button>{' '}
        <span className="bottom-table">
          Page{' '}
          <strong>
            {pageIndex + 1} sur {pageOptions.length}
          </strong>{' '}
        </span>
        <Form.Select
          className="d-inline-block w-auto ms-3 table-select"
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value));
          }}
          aria-label="Default select example"
        >
          {[15, 30, 50, 100, 150].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Afficher {pageSize} éléments
            </option>
          ))}
        </Form.Select>
        <span className="ps-2 smaller-p">
          Total : {data.length} élément{data.length > 1 ? 's' : ''}
        </span>
        <ExportTickets tickets={data} />
      </div>
    </>
  );
}

Table.propTypes = {
  data: PropTypes.array,
  setTrigger: PropTypes.func
};

TableTickets.propTypes = {
  initialApiObjects: PropTypes.array,
  companyContext: PropTypes.any,
  objects: PropTypes.array,
  setObjects: PropTypes.func
};

export default TableTickets;
