import React, { Fragment, useEffect, useState } from 'react';
import wave from 'assets/img/wave.png';
import { Button, Card, Col, Offcanvas, Row } from 'react-bootstrap';
import { ShowTicket } from '../../../../services/TicketService';
import HeaderEmployee from '../header';
import * as Moment from 'moment/moment';
import QRCode from 'react-qr-code';
import Avatar from '../../../common/Avatar';
import SoftBadge from '../../../common/SoftBadge';
import Lottie from 'react-lottie-player';
import code from 'assets/img/animated-icons/code-alt.json';
import { useParams } from 'react-router';

export default function ShowPageTicket() {
  const [show, setShow] = useState(false);
  const [object, setObject] = useState([]);
  const { uuid } = useParams();

  const formatter = new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR'
  });

  useEffect(() => {
    const fetchObject = async () => {
      const responseObject = await ShowTicket(uuid);
      if (responseObject.success === true) {
        setObject(responseObject.data);
      } else {
        alert("Votre ticket n'est plus valide pour aujourd'hui.");
        window.location.href = '/salaries/accueil';
      }
    };
    fetchObject();
  }, []);

  return (
    <Row>
      <Col xs={12} className="px-0">
        <HeaderEmployee
          isSmall={true}
          title={
            <>
              {object.created_at && (
                <Fragment>
                  Ticket{' '}
                  {Moment(object.created_at).local().format('DD/MM/YYYY')}
                </Fragment>
              )}
            </>
          }
        />
        <img src={wave} alt="picture-wave" className="wavePicture" />
      </Col>
      {object && object.uuid && object.user && (
        <Col xs={12}>
          <Row className="justify-content-center">
            <Col xs={12}>
              <Row className="align-items-center">
                <Col xs={2} className="mt-1">
                  <Button
                    className="align-items-center px-2 py-2"
                    onClick={() => {
                      window.location.href = '/salaries/accueil';
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        d="M10.828 12l4.95 4.95-1.414 1.414L8 12l6.364-6.364 1.414 1.414z"
                        fill="rgba(255,255,255,1)"
                      />
                    </svg>
                  </Button>
                </Col>
                <Col
                  xs={8}
                  className="mt-1"
                  onClick={() => {
                    setShow(true);
                  }}
                >
                  <h2>{`${object.user.lastname} ${object.user.firstname}`}</h2>

                  <h3 className="pt-0 d-block" style={{ fontSize: '18px' }}>
                    {object.user.company?.name}
                    {object.user.registration_number && (
                      <Fragment> - {object.user.registration_number}</Fragment>
                    )}
                  </h3>
                </Col>
              </Row>
            </Col>
            <Col
              xs={11}
              className="mt-2"
              onClick={() => {
                setShow(true);
              }}
            >
              <Avatar
                src={object.user.profile_picture_url}
                rounded="circle"
                size="5xl"
              />
            </Col>
            {object.user.company?.formulas &&
              object.user.company?.formulas.length > 0 && (
                <Col xs={11} className="mt-2">
                  <Row className="justify-content-center">
                    {object.user.company?.formulas.map((formula, index) => (
                      <Col key={index} xs={6} className="px-2">
                        <Card
                          bg="light"
                          text="dark"
                          onClick={() => {
                            setShow(true);
                          }}
                        >
                          <Card.Body className="px-0 pb-2 pt-0">
                            <Card.Title
                              as="div"
                              className="mb-0 pt-1"
                              style={{ fontSize: '17px', lineHeight: '17px' }}
                            >
                              <small>{formula.name}</small>
                            </Card.Title>
                            <SoftBadge bg="primary">
                              Remise :{' '}
                              {formatter.format(formula.calculated_value)}
                            </SoftBadge>
                          </Card.Body>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </Col>
              )}
            <Col
              xs={9}
              className="mt-3 mb-5"
              onClick={() => {
                setShow(true);
              }}
            >
              <div style={{ background: 'white', padding: '16px' }}>
                <QRCode
                  size={256}
                  style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                  value={object.uuid}
                  viewBox={`0 0 256 256`}
                />
              </div>
            </Col>
          </Row>
        </Col>
      )}
      <Offcanvas
        show={show}
        placement="bottom"
        onHide={() => {
          setShow(false);
        }}
      >
        <Offcanvas.Header closeButton>
          <b>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="19"
              height="19"
              style={{ marginBottom: '3px', marginRight: '3px' }}
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-5h2v2h-2v-2zm2-1.645V14h-2v-1.5a1 1 0 0 1 1-1 1.5 1.5 0 1 0-1.471-1.794l-1.962-.393A3.501 3.501 0 1 1 13 13.355z" />
            </svg>
            Aide
          </b>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row className="align-items-center">
            <Col xs={5}>
              <Lottie
                loop={true}
                animationData={code}
                play={true}
                style={{
                  width: '100%',
                  height: '100%',
                  margin: '0 auto'
                }}
              />
            </Col>
            <Col xs={7}>
              Rendez-vous en caisse pour scanner le code ci-dessus et appliquer
              votre remise.
            </Col>
          </Row>
        </Offcanvas.Body>
      </Offcanvas>
    </Row>
  );
}
