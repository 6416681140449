import React from 'react';
import ManageBackUsers from '../manage';

const ManageAccountants = () => {
  return (
    <ManageBackUsers context="comptable" urlElement="comptables" role_id={3} />
  );
};

export default ManageAccountants;
