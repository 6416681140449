export default function RedirectIfAuthenticated(navigate) {
  if (localStorage.getItem('accessToken') && localStorage.getItem('user')) {
    const user = JSON.parse(localStorage.getItem('user'));
    switch (user.role_id) {
      case 1:
        navigate('/administration/entreprises');
        break;
      case 2:
        navigate('/salaries/accueil');
        break;
      case 3:
        navigate('/comptabilite/tableau-de-bord');
        break;
      case 4:
        navigate('/caissiers/accueil');
        break;
    }
  }
}
