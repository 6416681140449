import React from 'react';
import ManageBackUsers from '../manage';

const ManageCashiers = () => {
  return (
    <ManageBackUsers context="caissier" urlElement="caissiers" role_id={4} />
  );
};

export default ManageCashiers;
