import ModelService from './ModelService';
const modelService = new ModelService();

const model = 'tickets';

export async function IndexTicket() {
  return modelService.IndexModel(model);
}

export async function ShowTicket(uuid) {
  return modelService.ShowModel(`${model}/${uuid}`);
}

export async function ShowTicketCashier(uuid) {
  return modelService.ShowModel(`${model}-cashier/${uuid}`);
}

export async function PostTicket() {
  return modelService.PostModel(`${model}`, {});
}

export async function PutTicket(formData, uuid) {
  return modelService.PutModel(`${model}/${uuid}`, formData);
}
