import React, { useState, useEffect } from 'react';
import { Offcanvas, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { DeleteCompany } from '../../../../services/CompanyService';
import { toast } from 'react-toastify';

const ManageDeleteCompany = ({
  showDeleteCompany,
  setShowDeleteCompany,
  object
}) => {
  const [formData, setFormData] = useState({
    name: ''
  });
  useEffect(() => {
    if (object) {
      setFormData({
        name: object.name
      });
    }
  }, []);

  const handleSubmit = async e => {
    e.preventDefault();
    let response = false;
    if (object) {
      response = await DeleteCompany(object.id, formData);
    }
    if (response.success) {
      setShowDeleteCompany(false);
      window.location.href = '/administration/entreprises';
      toast('Société supprimée');
    }
  };

  return (
    <>
      <Offcanvas
        placement={'end'}
        show={showDeleteCompany}
        onHide={() => {
          setShowDeleteCompany(false);
        }}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Voulez-vous continuer ?</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <p>
            Supprimer la société :{' '}
            <span style={{ fontWeight: 'bold' }}>{object.name}</span> ?
          </p>

          <Button
            variant="falcon-danger"
            onClick={handleSubmit}
            size="sm"
            className="mt-3"
          >
            Oui, supprimer la société
          </Button>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default ManageDeleteCompany;

ManageDeleteCompany.propTypes = {
  showDeleteCompany: PropTypes.bool,
  object: PropTypes.object,
  setShowDeleteCompany: PropTypes.func
};
