import React, { useContext, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import AppContext from 'context/Context';
import ProductProvider from 'components/app/e-commerce/ProductProvider';
import classNames from 'classnames';
import { useNavigate } from 'react-router';
import RequireAuthAdmin from '../../../middlewares/RequireAuthAdmin';
import NavbarTop from '../navbar/top/NavbarTop';
import NavbarVertical from '../navbar/vertical/NavbarVertical';
import Footer from '../footer/Footer';

const AdminLayout = () => {
  const { hash, pathname } = useLocation();
  const isKanban = pathname.includes('kanban');
  // const isChat = pathname.includes('chat');
  const navigate = useNavigate();

  const {
    config: { isFluid, navbarPosition }
  } = useContext(AppContext);

  useEffect(() => {
    RequireAuthAdmin(navigate);
    setTimeout(() => {
      if (hash) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
      }
    }, 0);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className={isFluid ? 'container-fluid' : 'container'}>
      {(navbarPosition === 'vertical' || navbarPosition === 'combo') && (
        <NavbarVertical />
      )}
      <ProductProvider>
        <div className={classNames('content', { 'pb-0': isKanban })}>
          <NavbarTop />
          {/*------ Main Routes ------*/}
          <Outlet />
          <Footer />
        </div>
      </ProductProvider>
    </div>
  );
};

export default AdminLayout;
