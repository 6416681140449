import ModelService from './ModelService';
const modelService = new ModelService();

const model = 'conditions';

export async function PutCondition(id, formData) {
  return modelService.PutModel(`${model}/${id}`, formData);
}

export async function PostCondition(formData) {
  return modelService.PostModel(`${model}`, formData);
}

export async function DeleteCondition(id) {
  return modelService.DeleteModel(`${model}/${id}`);
}
