import React, { Fragment, useEffect, useState } from 'react';
import { Card, Col, Row, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import TableEmployees from './table';
import { IndexTicket } from '../../../../services/TicketService';
import Flex from '../../../common/Flex';
import corner1 from 'assets/img/icons/spot-illustrations/corner-3.png';
import Background from '../../../common/Background';

const IndexTickets = () => {
  const [trigger] = useState(Date.now());
  const [initialApiObjects, setInitialApiObjects] = useState([]);
  const [objects, setObjects] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const formatter = new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR'
  });

  useEffect(() => {
    const fetchObjects = async () => {
      const responseObjects = await IndexTicket();
      if (responseObjects.success === true) {
        setInitialApiObjects(responseObjects.data);
        setIsLoading(false);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    fetchObjects();
  }, [trigger]);

  return (
    <>
      <Card>
        <Card.Body className="overflow-hidden px-lg-3">
          <Row className="justify-content-between align-items-center">
            <Col>
              <h3 className="text-primary admin-title mb-0">Tableau de bord</h3>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {isLoading === true && (
        <Row>
          <Col xs={12} className="mt-3">
            <Spinner animation="border" role="status" size="xs">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Col>
        </Row>
      )}
      {initialApiObjects.length > 0 && (
        <>
          <Row className="mt-3">
            <Col xs={4}>
              <Card className="h-md-100">
                <Background
                  image={corner1}
                  className="p-card bg-card"
                  style={{ zIndex: '1' }}
                />
                <Card.Header className="pb-0" style={{ zIndex: '9' }}>
                  <h6 className="mb-0 mt-2">Tickets générés</h6>
                </Card.Header>

                <Card.Body
                  as={Flex}
                  alignItems="end"
                  justifyContent="between"
                  style={{ zIndex: '9' }}
                >
                  <div>
                    <h2 className="mb-1 text-700 fw-normal lh-1">
                      <small>{objects.length}</small>
                    </h2>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={4}>
              <Card className="h-md-100">
                <Background
                  image={corner1}
                  className="p-card bg-card"
                  style={{ zIndex: '1' }}
                />
                <Card.Header className="pb-0" style={{ zIndex: '9' }}>
                  <h6 className="mb-0 mt-2">Participation totale</h6>
                </Card.Header>

                <Card.Body
                  as={Flex}
                  alignItems="end"
                  justifyContent="between"
                  style={{ zIndex: '9' }}
                >
                  <div>
                    <h2 className="mb-1 text-700 fw-normal lh-1">
                      <small>
                        {formatter.format(
                          Math.round(
                            objects.reduce((accumulator, object) => {
                              return (
                                accumulator + parseFloat(object.value_toString)
                              );
                            }, 0) * 100
                          ) / 100
                        )}
                      </small>
                    </h2>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={4}>
              <Card className="h-md-100">
                <Background
                  image={corner1}
                  className="p-card bg-card"
                  style={{ zIndex: '1' }}
                />
                <Card.Header className="pb-0" style={{ zIndex: '9' }}>
                  <h6 className="mb-0 mt-2">Participation moyenne</h6>
                </Card.Header>

                <Card.Body
                  as={Flex}
                  alignItems="end"
                  justifyContent="between"
                  style={{ zIndex: '9' }}
                >
                  <div>
                    <h2 className="mb-1 text-700 fw-normal lh-1">
                      <small>
                        {objects.length > 0 ? (
                          <Fragment>
                            {formatter.format(
                              Math.round(
                                objects.reduce((accumulator, object) => {
                                  return (
                                    accumulator +
                                    parseFloat(object.value_toString)
                                  );
                                }, 0) * 100
                              ) /
                                100 /
                                objects.length
                            )}
                          </Fragment>
                        ) : (
                          formatter.format(0)
                        )}
                      </small>
                    </h2>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <TableEmployees
            initialApiObjects={initialApiObjects}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            objects={objects}
            setObjects={setObjects}
          />
        </>
      )}
    </>
  );
};

export default IndexTickets;
